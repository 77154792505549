import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import Immutable from "immutable";
import Select from "js/common/views/inputs/immutable-react-select";
import {isDesktop} from "js/common/ua-parser";

export default createReactClass({

  displayName: "KpiPicker",

  propTypes: {
    onChange: ReactPropTypes.func.isRequired,
    kpis: ImmutablePropTypes.list.isRequired,
    selectedKpiId: ReactPropTypes.number,
    selectedKpiIds: ImmutablePropTypes.list,
    multiSelect: ReactPropTypes.bool,
    disableNonClientFilterableKpis: ReactPropTypes.bool,
    disableNonTargetKpis: ReactPropTypes.bool,
    isDisabled: ReactPropTypes.bool,
    placeholder: ReactPropTypes.string,
    clearable: ReactPropTypes.bool,
    id: ReactPropTypes.string
  },

  getDefaultProps() {
    return {
      disableNonClientFilterableKpis: false,
      disableNonTargetKpis: false,
      isDisabled: false,
      multiSelect: true,
      clearable: true,
      autosize: true,
      selectedKpiId: null,
      selectedKpiIds: null
    };
  },

  render() {
    const {
      multiSelect,
      clearable,
      kpis,
      selectedKpiId,
      selectedKpiIds,
      onChange,
      disableNonClientFilterableKpis,
      disableNonTargetKpis,
      isDisabled,
      id
    } = this.props;

    let options = toOptions(kpis, disableNonClientFilterableKpis, disableNonTargetKpis, selectedKpiId);

    return (
        <Select
            id={id}
            isMulti={multiSelect}
            isClearable={clearable}
            placeholder={this.getPlaceholder()}
            optionRenderer={optionRenderer}
            options={options}
            selectedValue={selectedKpiId}
            selectedValues={selectedKpiIds}
            onChange={onChange}
            isSearchable={isDesktop()}
            isDisabled={isDisabled} />
    );
  },

  getPlaceholder() {
    const {placeholder, multiSelect} = this.props;
    if (placeholder) {
      return placeholder;
    } else {
      return multiSelect ? "Select Metrics" : "Select a Metric";
    }
  }

});

const optionRenderer = option => <span>{option.label} {option.disabled && getClientFilterWarning()}</span>;

const getClientFilterWarning = () => (
    <span style={{color: "#f2e207"}}>
        <i className="fa fa-exclamation-triangle" style={{padding: "0 5px"}} />
        Not Client Filterable
    </span>);

const toOptions = (kpis, disableNonClientFilterableKpis = false, disableNonTargetKpis = false, selectedKpiId) => {

  const filteredKpis = disableNonTargetKpis ? kpis.filter(kpi => kpi.get("visible") && kpi.get("targetable")) : kpis;

  return filteredKpis
      .filter(kpi => !kpi.get("deleted") || kpi.get("id") === selectedKpiId)
      .map(kpi => Immutable.fromJS({
        value: kpi.get("id"),
        label: kpi.get("name"),
        disabled: disableNonClientFilterableKpis && !kpi.get("filterableByClient")
      }))
};
