import Immutable from "immutable";

import {getKpiIdFromArg, getKpiValue} from "js/common/formulas/utils";
import * as Kpis from "js/common/repo/backbone/kpi-repo";

export default Immutable.fromJS([{
  id: "metric-value",
  getKpiIds: (context, masterKpiTypeToKpiId, idToNode, idToFormula, formulaIdAndArgsToCachedKpiIds, args) => {
    return {kpiIds: Immutable.Set.of(getKpiIdFromArg(args.first(), masterKpiTypeToKpiId)), errors: Immutable.Set()};
  },
  getLabel: (context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, callId, args) => {
    const kpiId = getKpiIdFromArg(args.first(), masterKpiTypeToKpiId);
    return {result: Kpis.get(kpiId).get("name"), errors: Immutable.Set()};
  },
  fn: (context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, args) => {
    const kpiId = getKpiIdFromArg(args.first(), masterKpiTypeToKpiId);
    const total = getKpiValue(kpiId, kpiIdToValue).get("total");
    if (total.has("formatAs")) {
      return {result: total, errors: Immutable.Set()};
    } else {
      const valueFormat = Kpis.get(kpiId).get("valueFormat");
      return {result: total.set("formatAs", valueFormat), errors: Immutable.Set()};
    }
  }
}, {
  id: "metric-target",
  getKpiIds: (context, masterKpiTypeToKpiId, idToNode, idToFormula, formulaIdAndArgsToCachedKpiIds, args) => {
    return {kpiIds: Immutable.Set.of(getKpiIdFromArg(args.first(), masterKpiTypeToKpiId)), errors: Immutable.List()};
  },
  getLabel: (context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, callId, args) => {
    const kpiId = getKpiIdFromArg(args.first(), masterKpiTypeToKpiId);
    return {result: Kpis.get(kpiId).get("name"), errors: Immutable.Set()}
  },
  fn: (context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, args) => {
    const kpiId = getKpiIdFromArg(args.first(), masterKpiTypeToKpiId);
    const target = getKpiValue(kpiId, kpiIdToValue).get("target");
    if (target.has("formatAs")) {
      return {result: target, errors: Immutable.Set()};
    } else {
      const valueFormat = Kpis.get(kpiId).get("valueFormat");
      return {result: target.set("formatAs", valueFormat), errors: Immutable.Set()};
    }
  }
}]);
