import moment from "moment";

import eventBus from "js/cube19.event-bus";
import currentClient from "js/common/repo/backbone/current-client";
import Immutable from "immutable";


const Backbone = window.Backbone;
const _ = window._;

const mysqlDatePattern = "YYYY-MM-DD";

const TimeframeModel = Backbone.Model.extend({

    parse(attributes) {
        const timeframeDescription = !attributes.isCube19Default ?
            parseTimeframeDescription(attributes.timeframeDescription) : attributes.timeframeDescription;
        return {
            id: attributes.id.toLowerCase(),
            trueName: attributes.trueName,
            name: attributes.name,
            shortName: attributes.shortName,
            isCube19Default: attributes.isCube19Default,
            start: moment(attributes.start, mysqlDatePattern),
            end: moment(attributes.end, mysqlDatePattern),
            timeframeDescription,
            order: attributes.order,
            visible: attributes.visible,
            isDeleted: attributes.isDeleted
        };
    },

    getStart() {
        return this.get("start").format(mysqlDatePattern);
    },

    getEnd() {
        return this.get("end").format(mysqlDatePattern);
    },

    getRawJson() {
        return {
            type: this.get("id"),
            start: this.get("start").format(mysqlDatePattern),
            end: this.get("end").format(mysqlDatePattern)
        };
    },
    getImmutableTimeframe() {
        const type = this.get("id").toUpperCase();
        if (type === "CUSTOM") {
            return Immutable.fromJS({
                type,
                startDate: this.get("start").format(mysqlDatePattern),
                endDate: this.get("end").format(mysqlDatePattern)
            });
        } else {
            return Immutable.fromJS({type});
        }
    }

});

const parseTimeframeDescription = timeframeDescription => {
    if (timeframeDescription.timeframeType === "FIXED") {
        const start = timeframeDescription.start;
        const end = timeframeDescription.end;
        return {
            timeframeType: timeframeDescription.timeframeType,
            start: {
                ...start,
                date: moment(start.date, mysqlDatePattern)
            },
            end: {
                ...end,
                date: moment(end.date, mysqlDatePattern)
            }
        };
    } else {
        return timeframeDescription;
    }
};

const TimeframeCollection = Backbone.Collection.extend({
    url: "timeframe",
    model: TimeframeModel,
    comparator: timeframe => timeframe.get("order")
});

const timeframes = new TimeframeCollection();
const load = () => timeframes
    .fetch({
        data: {
            today: moment().format(mysqlDatePattern)
        },
        processData: true
    })
    .then(() => {
        eventBus.trigger("data-loaded", "timeframes");
    }, error => {
        eventBus.trigger("error:fatal", error);
    });

const reload = () => timeframes.fetch({
    data: {
        today: moment().format(mysqlDatePattern)
    },
    processData: true
});

const get = id => timeframes.get(id.toLowerCase());
const getAll = () => timeframes;
const getDefaultForClient = () => get(currentClient.get("timeframe"));

const parse = (attributes, pattern = mysqlDatePattern) => {
    const type = attributes.type.toLowerCase();
    // parse raw JSON object from getRawJson() method in timeframe model
    return type === "custom" ? parseCustom(attributes, pattern) : get(type);
};

const parseCustom = (attributes, pattern = mysqlDatePattern) => {
    const start = _.isString(attributes.start) ? moment(attributes.start, pattern) : attributes.start;
    const end = _.isString(attributes.end) ? moment(attributes.end, pattern) : attributes.end;
    return new TimeframeModel({
        id: "custom",
        name: getCustomDateRangeDisplayText(start, end),
        start: start,
        end: end,
        visible: true,
        isDeleted: false
    });
};

const parseFromImmutable = (immutableTimeframe) =>  {
    return parse({
        type: immutableTimeframe.get("type"),
        start: immutableTimeframe.get("startDate"),
        end: immutableTimeframe.get("endDate")
    });
}

const getCustomDateRangeDisplayText = (start, end) => formatDate(start) + " to " + formatDate(end);
const formatDate = date => date.format("ll").replace(",", "");

export {
    load,
    reload,
    getDefaultForClient,
    get,
    getAll,
    parseCustom,
    parse,
    parseFromImmutable
};
