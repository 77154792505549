import React from "react";
import createReactClass from "create-react-class";
import Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";
import GetContainerDimensions from "react-dimensions";

import LoadingScreen from "js/common/views/loading/loading-screen";
import UncaughtErrorMsg from "js/common/views/uncaught-error-msg";
import CurrentLeaderboard from "js/cubetv/cubes/current-leaderboard";
import NewLeaderboard from "js/cubetv/cubes/new-leaderboard";
import CurrentTopPerformerPanel from "js/cubetv/cubes/current-top-performer-panel";
import NewTopPerformerPanel from "js/cubetv/cubes/new-top-performer-panel";
import CurrentGroupOverviewPanel from "js/cubetv/cubes/current-group-overview-panel";
import NewGroupOverviewPanel from "js/cubetv/cubes/new-group-overview-panel";
import * as timeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as kpiCalculator from "js/common/kpi-calculator";
import {rankByPercent, rankByValue} from "js/cubetv/cubes/leaderboard-ranker";
import retry from "js/common/retryable";
import currentClient from "js/common/repo/backbone/current-client";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";
import * as auditor from "js/common/auditer";
import BaseCube from "js/cubetv/cubes/models/base-cube";
import Cube19 from "js/cube19.app";
import * as Colors from "js/common/cube19-colors";
import {indexBy} from "js/common/utils/collections";

const rowsPerPage = 10;

const kpiDataValueError = Immutable.fromJS({
  type: "error",
  value: "N/A"
});

const LeaderboardModel = BaseCube.extend({

  loadData() {
    this.set({
      id: Math.random(),
      isLeaderboardDataLoaded: false,
      isTopPerformersDataLoaded: false,
      isGroupOverviewDataLoaded: false,
      leaderboardFatalError: false
    });
    this.loadLeaderboardData();
  },

  loadLeaderboardData() {
    const leaderboardConfig = this.get("leaderboardConfig");
    const requestOptions = {
      groupId: leaderboardConfig.groupId,
      timeframe: timeframeRepo.get(leaderboardConfig.timeframeId),
      matchAnyTagIds: leaderboardConfig.matchAnyTagIds,
      matchAllTagIds: leaderboardConfig.matchAllTagIds,
      excludedTagIds: leaderboardConfig.excludedTagIds
    };
    const {targetedLeaderboard, leaderboard} = kpiCalculator;
    const loadData = leaderboardConfig.rankByPercentOfTarget ? targetedLeaderboard : leaderboard;
    const {rankedKpiId, rankedKpiTemplateName, otherKpiId, otherKpiTemplateName} = leaderboardConfig;
    const rankedKpi = kpiRepo.getByIdOrTemplateName(rankedKpiId || rankedKpiTemplateName);
    const otherKpi = kpiRepo.getByIdOrTemplateName(otherKpiId || otherKpiTemplateName);
    const group = Groups.getGroup(leaderboardConfig.groupId);
    if (!group) {
      auditor.audit("deleted-group:leaderboard-slide");
    }
    if (group && rankedKpi && otherKpi) {
      Promise
          .all([
            retry(() => loadData(rankedKpi.get("id"), requestOptions)),
            retry(() => loadData(otherKpi.get("id"), requestOptions))
          ])
          .then(results => {
            const rankedKpiData = Immutable.fromJS(results[0]);
            const userIdToOtherKpiData = indexBy(data => data.get("userId"), Immutable.fromJS(results[1]));
            const sortedRankedKpiData = this.processRankedKpiData(rankedKpiData);
            const leaderboardData = sortedRankedKpiData.map(kpiData => {
              const userId = kpiData.get("userId");
              const otherKpiDataForUser = userIdToOtherKpiData.getIn([userId, "kpi"]);

              if (otherKpiDataForUser) {
                return kpiData.set("otherKpi", otherKpiDataForUser);
              } else {
                const isMonetaryValue = otherKpi.get("valueFormat") === "CURRENCY";
                let placeholderKpiData;
                if (isMonetaryValue) {
                  placeholderKpiData = getPlaceholderMonetaryKpiData(group.get("currencyCode"));
                } else {
                  placeholderKpiData = getPlaceholderNumericalKpiData();
                }
                return kpiData.set("otherKpi", placeholderKpiData);
              }
            });
            const topPerformers = leaderboardData
                .take(leaderboardConfig.limit)
                .map(data => Immutable.fromJS({
                  userId: data.get("userId"),
                  rank: data.get("rank")
                }));
            this.set({
              leaderboardDataByPage: this.getLeaderboardDataByPage(leaderboardData),
              topPerformers,
              isLeaderboardDataLoaded: true
            });
            this.trigger("change");
            this.loadTopPerformersData(topPerformers.map(u => u.get("userId")));
            this.loadGroupOverviewData();
          }, () => {
            this.set({
              leaderboardFatalError: true,
              isLeaderboardDataLoaded: true
            });
            this.trigger("change");
          });
    } else if (group && rankedKpi && !otherKpi) {
      retry(() => loadData(rankedKpi.get("id"), requestOptions))
          .then(results => {
            const sortedRankedKpiData = this.processRankedKpiData(Immutable.fromJS(results));
            const topPerformers = sortedRankedKpiData
                .take(leaderboardConfig.limit)
                .map(data => Immutable.fromJS({
                  userId: data.get("userId"),
                  rank: data.get("rank")
                }));
            this.loadTopPerformersData(topPerformers.map(u => u.get("userId")));
            this.loadGroupOverviewData();
            this.set({
              leaderboardDataByPage: this.getLeaderboardDataByPage(sortedRankedKpiData),
              topPerformers,
              isLeaderboardDataLoaded: true
            });
            this.trigger("change");
          }, () => {
            this.set({
              leaderboardFatalError: true,
              isLeaderboardDataLoaded: true
            });
            this.trigger("change");
          });
    } else {
      this.set({
        leaderboardFatalError: true,
        isLeaderboardDataLoaded: true
      });
      this.trigger("change");
    }
  },

  processRankedKpiData(rankedKpiData) {
    const leaderboardConfig = this.get("leaderboardConfig");
    let filteredRankedKpiData = rankedKpiData
        .filter(data => !!Users.getUser(data.get("userId")));
    if (leaderboardConfig.excludeZeroValues) {
      filteredRankedKpiData = filteredRankedKpiData.filter(data => data.getIn(["kpi", "total", "value"]));
    }
    const rankData = leaderboardConfig.rankByPercentOfTarget ? rankByPercent : rankByValue;
    return rankData(filteredRankedKpiData)
        .map(kpiData => kpiData
            .set("rankedKpi", kpiData.get("kpi"))
            .delete("kpi"));
  },

  getLeaderboardDataByPage(leaderboardData) {
    const totalRows = leaderboardData.count();
    const maxRows = this.get("leaderboardConfig").totalUsers;
    let totalLeaderboardPages = 1;
    if (totalRows > 0) {
      const totalVisibleRows = totalRows > maxRows ? maxRows : totalRows;
      totalLeaderboardPages = Math.ceil(totalVisibleRows / rowsPerPage);
    }
    let page = 1;
    let leaderboardDataByPage = Immutable.Map();
    while (page <= totalLeaderboardPages) {
      const startIndex = (page - 1) * rowsPerPage;
      const endIndex = page * rowsPerPage;
      const dataRowsForPage = leaderboardData.slice(startIndex, endIndex);
      leaderboardDataByPage = leaderboardDataByPage.set(page, dataRowsForPage);
      page++;
    }
    return leaderboardDataByPage;
  },

  loadTopPerformersData(userIds) {
    const topPerformersConfig = this.get("topPerformersConfig");
    const kpis = topPerformersConfig.kpis;
    if (!kpis || kpis.length === 0) {
      this.set("isTopPerformersDataLoaded", true);
      this.trigger("change");
    } else {
      let topPerformersData = Immutable.Map();
      let kpiPromises = Immutable.List();
      userIds.forEach(userId => {
        kpis.forEach((kpiConfig, index) => {
          const order = kpiConfig.order || (index + 1);
          const kpi = kpiRepo.getByIdOrTemplateName(kpiConfig.kpiId || kpiConfig.templateName);
          const kpiId = kpi.get("id");
          const timeframeId = kpiConfig.timeframeId;
          const timeframe = timeframeRepo.get(timeframeId);
          const user = Users.getUser(userId);
          const requestOptions = {
            userId,
            groupId: user.get("groupId"),
            timeframe,
            matchAnyTagIds: kpiConfig.matchAnyTagIds,
            matchAllTagIds: kpiConfig.matchAllTagIds,
            excludedTagIds: kpiConfig.excludedTagIds
          };
          const defaultKpiData = Immutable.fromJS({
            userId,
            kpiId,
            timeframeId,
            order,
            displayName: kpiConfig.displayName || getDefaultDisplayName(kpi, timeframe)
          });
          const promise = kpiCalculator
              .summary(kpiId, requestOptions)
              .then(
                  response => defaultKpiData.set("total", Immutable.fromJS(response.total)),
                  () => defaultKpiData.set("total", kpiDataValueError));
          kpiPromises = kpiPromises.push(promise);
        });
      });
      Promise
          .all(kpiPromises)
          .then(results => {
            results.forEach(kpiData => {
              const key = Immutable.fromJS({
                userId: kpiData.get("userId"),
                kpiId: kpiData.get("kpiId"),
                timeframeId: kpiData.get("timeframeId")
              });
              topPerformersData = topPerformersData.set(key, kpiData);
            });
            this.set({
              topPerformersDataByUserIdKpiIdTimeframeId: topPerformersData,
              isTopPerformersDataLoaded: true
            });
            this.trigger("change");
          });
    }
  },

  loadGroupOverviewData() {
    const groupOverviewConfig = this.get("groupOverviewConfig");
    const kpis = groupOverviewConfig.kpis;
    if (!kpis || kpis.length === 0) {
      this.set("isGroupOverviewDataLoaded", true);
      this.trigger("change");
    } else {
      let groupId = groupOverviewConfig.groupId;
      if (groupId && !Groups.getGroup(groupId)) {
        auditor.audit("deleted-group:leaderboard-slide");
      }
      if (!groupId || groupId === "root") {
        const rootGroup = Groups.getRootGroup();
        groupId = rootGroup.get("id");
      }
      const kpiPromises = kpis.map((kpiConfig, index) => {
        const order = kpiConfig.order || (index + 1);
        const kpi = kpiRepo.getByIdOrTemplateName(kpiConfig.kpiId || kpiConfig.templateName);
        const kpiId = kpi.get("id");
        const timeframeId = kpiConfig.timeframeId;
        const timeframe = timeframeRepo.get(timeframeId);
        const requestOptions = {
          timeframe,
          groupId,
          matchAnyTagIds: groupOverviewConfig.matchAnyTagIds,
          matchAllTagIds: groupOverviewConfig.matchAllTagIds,
          excludedTagIds: groupOverviewConfig.excludedTagIds
        };
        const defaultKpiData = Immutable.fromJS({
          kpiId,
          timeframeId,
          order,
          displayName: kpiConfig.displayName || getDefaultDisplayName(kpi, timeframe)
        });
        return kpiCalculator
            .summary(kpiId, requestOptions)
            .then(
                response => defaultKpiData.set("total", Immutable.fromJS(response.total)),
                () => defaultKpiData.set("total", kpiDataValueError));
      });
      Promise
          .all(kpiPromises)
          .then(results => {
            this.set({
              groupOverviewData: Immutable.fromJS(results),
              isGroupOverviewDataLoaded: true
            });
            this.trigger("change");
          });
    }
  }

});

Cube19.module("Models.Cubes", function(Cubes, App, Backbone, Marionette, $, _) {

  Cubes.Leaderboard = LeaderboardModel;

});

export default (props) => {
  return <ErrorBoundaryPage {...props} />;
};

const ErrorBoundaryPage = createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      uncaughtError: false
    };
  },

  componentDidCatch() {
    this.setState({
      uncaughtError: true
    });
  },

  render() {
    if (this.state.uncaughtError) {
      return <UncaughtErrorMsg />;
    } else {
      return <Slide {...this.props} />;
    }
  }

});

const Slide = GetContainerDimensions()(createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    const client = currentClient;
    const {leaderboardConfig} = this.props;
    const isTopPerformerHighlightEnabled = leaderboardConfig.cycle && leaderboardConfig.limit > 0;
    return {
      showNewLeaderboardSlide: client.hasPermission("HAS_NEW_LOOK_CUBETV"),
      currentLeaderboardPage: 1,
      currentTopPerformerIndex: isTopPerformerHighlightEnabled ? 0 : null
    };
  },

  componentDidMount() {
    const {leaderboardDataByPage, leaderboardConfig, leaderboardFatalError} = this.props;
    if (leaderboardFatalError) {
      showNextSlide();
      return;
    } else if (this.isAllDataLoaded()) {
      const isTopPerformerHighlightEnabled = leaderboardConfig.cycle && leaderboardConfig.limit > 0;
      const isLeaderboardEmpty = leaderboardDataByPage.first().isEmpty();
      if (isTopPerformerHighlightEnabled && !isLeaderboardEmpty) {
        this.highlightTopPerformers();
      } else {
        this.setLeaderboardPageDisplayInterval();
      }
    }
  },

  componentWillUnmount() {
    window.clearInterval(this.topPerformersHighlightIntervalId);
    window.clearInterval(this.leaderboardPageDisplayIntervalId);
    window.clearTimeout(this.topPerformerToCompanyOverviewTimeoutId);
    window.clearTimeout(this.remainingLeaderboardPageDisplayTimeoutId);
  },

  render() {
    return (
        <div style={{width: "100%", height: "100%"}}>
          {(this.isAllDataLoaded() && !this.props.leaderboardFatalError)
              ? this.renderLeaderboardSlide()
              : <LoadingScreen />}
        </div>
    );
  },

  isAllDataLoaded() {
    return this.props.isLeaderboardDataLoaded
        && this.props.isTopPerformersDataLoaded
        && this.props.isGroupOverviewDataLoaded;
  },

  renderLeaderboardSlide() {
    const {leaderboardConfig, topPerformers, containerWidth } = this.props;
    const {currentTopPerformerIndex, showNewLeaderboardSlide} = this.state;
    const isTopPerformerHighlightEnabled = leaderboardConfig.cycle && leaderboardConfig.limit > 0;
    const isHighlightingTopPerformers = isTopPerformerHighlightEnabled &&
        currentTopPerformerIndex !== null &&
        currentTopPerformerIndex < leaderboardConfig.limit;
    const isSmallScreen = containerWidth < 1280;
    const containerStyle = {
      display: "flex",
      flexDirection: "row",
      flexGrow: 0,
      justifyContent: "space-between",
      height: "83vh",
      marginLeft: isSmallScreen ? "1rem" : "1.25rem",
      marginRight: isSmallScreen ? "1rem" : "1.25rem",
      borderTop: showNewLeaderboardSlide ? `3px solid ${Colors.c19Yellow}` : 0,
      paddingTop: showNewLeaderboardSlide ? "2vh" : 0,
      paddingBottom: showNewLeaderboardSlide ? "1vh" : 0,
      marginTop: showNewLeaderboardSlide ? 10 : 0
    };
    return (
        <div style={containerStyle}>
          <div style={{width: "70%", paddingRight: "0.5rem"}}>
            {this.renderLeaderboard()}
          </div>
          <div style={{width: "30%", paddingLeft: "0.5rem"}}>
            {isHighlightingTopPerformers && !topPerformers.isEmpty() ?
                this.renderCurrentTopPerformerSummary() : this.renderGroupOverview()}
          </div>
        </div>
    );
  },

  renderLeaderboard() {
    const {leaderboardConfig, leaderboardDataByPage} = this.props;
    const {currentLeaderboardPage, currentTopPerformerIndex} = this.state;
    const {
      rankedKpiId,
      rankedKpiTemplateName,
      rankedKpiColumnHeading,
      otherKpiId,
      otherKpiTemplateName,
      otherKpiColumnHeading
    } = leaderboardConfig;
    const leaderboardHasSecondKpi = otherKpiId || otherKpiTemplateName;
    const rankedKpi = kpiRepo.getByIdOrTemplateName(rankedKpiId || rankedKpiTemplateName);
    const otherKpi = kpiRepo.getByIdOrTemplateName(otherKpiId || otherKpiTemplateName);
    const Leaderboard = this.state.showNewLeaderboardSlide ? NewLeaderboard : CurrentLeaderboard;
    return (
        <Leaderboard
            rankedKpiColumnHeading={rankedKpiColumnHeading}
            rankedKpiId={rankedKpi.get("id")}
            otherKpiColumnHeading={otherKpiColumnHeading}
            otherKpiId={leaderboardHasSecondKpi ? otherKpi.get("id") : null}
            displayTotalAndPercentOfTarget={leaderboardConfig.rankByPercentOfTarget}
            highlightCurrentTopPerformerRow={leaderboardConfig.cycle}
            currentTopPerformerIndex={currentTopPerformerIndex}
            pageRows={leaderboardDataByPage.get(currentLeaderboardPage)} />
    );
  },

  renderCurrentTopPerformerSummary() {
    const {topPerformersConfig, topPerformers, topPerformersDataByUserIdKpiIdTimeframeId} = this.props;
    const currentTopPerformer = topPerformers.get(this.state.currentTopPerformerIndex);
    const requiredKpis = Immutable.fromJS(topPerformersConfig.kpis);
    const dataForCurrentTopPerformer = requiredKpis
        .map(requiredKpi => {
          const key = Immutable.Map({
            userId: currentTopPerformer.get("userId"),
            kpiId: requiredKpi.get("kpiId"),
            timeframeId: requiredKpi.get("timeframeId")
          });
          return topPerformersDataByUserIdKpiIdTimeframeId.get(key);
        });
    const TopPerformerPanel = this.state.showNewLeaderboardSlide ? NewTopPerformerPanel : CurrentTopPerformerPanel;
    return (
        <TopPerformerPanel
            config={Immutable.fromJS(topPerformersConfig)}
            topPerformer={currentTopPerformer}
            data={dataForCurrentTopPerformer} />
    );
  },

  renderGroupOverview() {
    const GroupOverviewPanel = this.state.showNewLeaderboardSlide ? NewGroupOverviewPanel : CurrentGroupOverviewPanel;
    const config = Immutable.fromJS(this.props.groupOverviewConfig);
    return <GroupOverviewPanel config={config} data={this.props.groupOverviewData} />;
  },

  highlightTopPerformers() {
    const {leaderboardConfig, leaderboardDataByPage, topPerformers} = this.props;
    const highlightTimeInMs = leaderboardConfig.cycleTime;
    this.topPerformersHighlightIntervalId = window.setInterval(() => {
      const totalHighlightRows = Math.min(leaderboardConfig.limit, topPerformers.count());
      if (this.state.currentTopPerformerIndex === totalHighlightRows - 1) {
        window.clearInterval(this.topPerformersHighlightIntervalId);
        this.setState({
          currentTopPerformerIndex: null
        });

        const totalLeaderboardPages = leaderboardDataByPage.count();
        this.topPerformerToCompanyOverviewTimeoutId = window.setTimeout(() => {
          const totalTopPerformersHighlightTimeInMs = highlightTimeInMs * leaderboardConfig.limit;
          const paginationTimeInMs = leaderboardConfig.paginationTime;
          const remainingDisplayTimeInMs = paginationTimeInMs - totalTopPerformersHighlightTimeInMs;
          if (remainingDisplayTimeInMs > 0) {
            this.remainingLeaderboardPageDisplayTimeoutId = window.setTimeout(() => {
              if (this.state.currentLeaderboardPage === totalLeaderboardPages) {
                showNextSlide();
              } else {
                this.showNextLeaderboardPage();
                this.setLeaderboardPageDisplayInterval();
              }
            }, remainingDisplayTimeInMs);
          } else if (this.state.currentLeaderboardPage === totalLeaderboardPages) {
            showNextSlide();
          } else {
            this.showNextLeaderboardPage();
            this.setLeaderboardPageDisplayInterval();
          }
        }, highlightTimeInMs);
      } else {
        const nextTopPerformerIndex = this.state.currentTopPerformerIndex + 1;
        this.setState({
          currentTopPerformerIndex: nextTopPerformerIndex
        });
      }
    }, highlightTimeInMs);
  },

  setLeaderboardPageDisplayInterval() {
    const {leaderboardConfig} = this.props;
    const paginationTimeInMs = leaderboardConfig.paginationTime;
    this.leaderboardPageDisplayIntervalId = window.setInterval(() => {
      this.showNextLeaderboardPage();
    }, paginationTimeInMs);
  },

  showNextLeaderboardPage() {
    const {currentLeaderboardPage} = this.state;
    const totalLeaderboardPages = this.props.leaderboardDataByPage.count();
    if (currentLeaderboardPage === totalLeaderboardPages) {
      window.clearInterval(this.leaderboardPageDisplayIntervalId);
      showNextSlide();
    } else {
      const nextLeaderboardPage = currentLeaderboardPage + 1;
      this.setState({
        currentLeaderboardPage: nextLeaderboardPage
      });
    }
  }

}));

const getPlaceholderNumericalKpiData = () => Immutable.fromJS({
  priority: false,
  expectedValue: null,
  target: null,
  total: {
    value: 0
  }
});

const getPlaceholderMonetaryKpiData = currencyCode => {
  const placeholderKpiData = getPlaceholderNumericalKpiData();
  const kpiTotal = placeholderKpiData.get("total");
  const monetaryKpiTotal = kpiTotal.set("currency", currencyCode);
  return placeholderKpiData.set("total", monetaryKpiTotal);
};

const showNextSlide = () => Cube19.execute("cubetv:show-next");

const getDefaultDisplayName = (kpi, timeframe) => {
  const timeframeName = timeframe.get("shortName") || timeframe.get("name");
  return `${kpi.get("name")} ${timeframeName}`;
};
