import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";

import LeaderboardContainer from "js/oneview/leaderboards/leaderboard-container";
import LoadingSpinner from "js/common/views/loading-spinner";

import * as KpiRepo from "js/common/repo/backbone/kpi-repo";
import * as KpiCalculator from "js/common/kpi-calculator";
import * as Auditor from "js/common/auditer";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";
import {rankByPercent, rankByValue} from "js/cubetv/cubes/leaderboard-ranker";
import saveAsCsv from "js/common/save-as-csv";
import {getPercentageOfTargetComplete} from "js/oneview/kpi-values-display";

import * as Formatter from "js/common/utils/formatter";

export default createReactClass({
  displayName: "LeaderboardCT",
  mixins: [PureRenderMixin],

  propTypes: {
    kpiId: ReactPropTypes.number.isRequired,
    timeframe: ReactPropTypes.object.isRequired,
    qualifierType: ReactPropTypes.string.isRequired,
    qualifierId: ReactPropTypes.number.isRequired,
    onUserClick: ReactPropTypes.func.isRequired,
    clientIds: ReactPropTypes.array
  },

  getInitialState() {
    return {
      isLoading: false,
      loadError: null,
      leaderboard: Immutable.List(),
      groupId: this.setInitialGroupId(),
      sortField: "VALUE",
      sortDescending: true,
      filterZero: false
    };
  },

  componentDidMount() {
    this.loadLeaderboard();
  },

  render() {
    const {isLoading, loadError, groupId, sortField, sortDescending, filterZero} = this.state;
    const userId = this.props.qualifierType === "USER" ? this.props.qualifierId : null;
    return (
        <React.Fragment>
          {isLoading && this.renderLoadingSpinner()}
          {!isLoading && !loadError &&
              <LeaderboardContainer
                  leaderboard={this.rankData()}
                  onSortChange={this.onSortChange}
                  sortField={sortField}
                  sortDescending={sortDescending}
                  groupId={groupId}
                  userId={userId}
                  filterZero={filterZero}
                  onFilterToggle={this.handleFilterToggle}
                  onGroupChange={this.handleGroupChange}
                  onUserClick={this.props.onUserClick}
                  onDownloadClick={this.handleDownloadClick} />}
        </React.Fragment>);
  },

  setInitialGroupId() {
    const {qualifierType, qualifierId} = this.props;

    let groupId;
    if (qualifierType === "GROUP") {
      groupId = qualifierId;
    } else {
      const user = Users.getUser(qualifierId);
      groupId = user.get("groupId");
    }

    return groupId;
  },

  loadLeaderboard() {
    const {kpiId, timeframe, clientIds, matchAnyTagIds, matchAllTagIds, excludedTagIds} = this.props;

    this.setState({
      isLoading: true
    });

    let leaderboardPromise;
    if (clientIds && clientIds.length > 0) {
      leaderboardPromise = loadLeaderboardData(kpiId, {
        qualifierType: "GROUP",
        qualifierId: this.state.groupId,
        timeframe,
        clientIds,
        matchAnyTagIds,
        matchAllTagIds,
        excludedTagIds
      });
    } else {
      leaderboardPromise = loadTargetedLeaderboardData(kpiId, {
        qualifierType: "GROUP",
        qualifierId: this.state.groupId,
        timeframe,
        matchAnyTagIds,
        matchAllTagIds,
        excludedTagIds
      });
    }
    leaderboardPromise.then(leaderboard => {
      this.setState({
        isLoading: false,
        leaderboard
      });
    }, () => {
      this.setState({
        isLoading: false,
        loadError: "Unable to load leaderboard"
      });
    });
  },

  renderLoadingSpinner() {
    return (
        <div style={{margin: "3rem"}}>
          <LoadingSpinner iconSize={3} label="Loading Leaderboard" />
        </div>);
  },

  onSortChange(sortField) {
    this.setState(state => {
      if (state.sortField === sortField) {
        const sortDescending = !state.sortDescending;
        return {sortDescending};
      } else {
        return {sortField, sortDescending: true};
      }
    });
  },

  rankData() {
    const sortFn = this.state.sortField === "VALUE" ? rankByValue : rankByPercent;
    const data = sortFn(this.state.leaderboard)
        .filter(row => this.state.filterZero ? row.getIn(["kpi", "total", "value"]) !== 0 : true)
        .map(row => mapToTableRow(row, this.props.kpiId));
    if (this.state.sortDescending) {
      return Immutable.fromJS(data);
    }
    return Immutable.fromJS(data.reverse());
  },

  handleFilterToggle(e, isChecked) {
    this.setState({filterZero: isChecked});
  },

  handleGroupChange(groupId) {
    this.setState({groupId}, () => this.loadLeaderboard());
  },

  handleDownloadClick(columns, rows) {
    const {kpiId, timeframe} = this.props;
    const {groupId} = this.state;
    const filenameForDownload = `${KpiRepo.get(kpiId)
        .get("name")} (${timeframe.get("name")}) Leaderboard for ${Groups.getGroupBreadcrumbsStr(groupId, ">")}.csv`;

    saveAsCsv([columns].concat(rows.toJS()), filenameForDownload);

    Auditor.audit("kpi_ct:download_leaderboard", {
      kpiId,
      groupId,
      timeframe: timeframe.get("id")
    });
  }

});

const mapToTableRow = (data, kpiId) => {
  const rank = data.get("rank");
  const userId = data.get("userId");
  const kpi = data.get("kpi");
  const kpiConfig = KpiRepo.get(kpiId);
  const valueFormat = kpiConfig.get("valueFormat");
  const target = kpi.get("target");
  const total = kpi.get("total");
  const totalValueDisplay = Formatter.format(total.toJS(), {valueFormat});
  const hasTarget = target && target.get("value") > 0;
  const targetValueDisplay = hasTarget ? Formatter.format(target.toJS(), {valueFormat}) : "";
  const percentOfTarget = hasTarget ? getPercentageOfTargetComplete(total.get("value"), target.get("value")) : "";
  return Immutable.fromJS({
    rank,
    userId,
    totalValueDisplay,
    targetValueDisplay,
    percentOfTarget
  });
};

const loadTargetedLeaderboardData = (kpiId, params) => KpiCalculator
    .targetedLeaderboard(kpiId, params)
    .then(data => Immutable.fromJS(data));

const loadLeaderboardData = (kpiId, params) => KpiCalculator
    .leaderboard(kpiId, params)
    .then(data => Immutable.fromJS(data));
