/** @jsxImportSource @emotion/react */

import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import PureRenderMixin from "react-addons-pure-render-mixin";

import {getPercentageOfTargetCompleteString} from "js/oneview/kpi-values-display";
import LoadingSpinner from "js/oneview/loading-spinner";
import CurveChart from "js/oneview/priority-kpi/priority-curve-chart";
import QuickViewButton from "js/oneview/quick-view-button";
import Tooltip from "js/common/views/tooltips";
import * as Formatter from "js/common/utils/formatter";
import {css} from "@emotion/react";
import currentClient from "js/common/repo/backbone/current-client";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import * as Users from "js/common/users";
import * as numbers from "js/common/utils/numbers";

const PriorityKpiCube = createReactClass({
  mixins: [PureRenderMixin],
  displayName: "PriorityKpi",

  propTypes: {
    kpiId: ReactPropTypes.number.isRequired,
    kpiData: ImmutablePropTypes.map,
    clientIds: ImmutablePropTypes.set,
    onClick: ReactPropTypes.func.isRequired,
    onQuickViewClick: ReactPropTypes.func.isRequired,
    hasAppliedTagFilters: ReactPropTypes.bool.isRequired
  },

  getInitialState() {
    return {
      isActive: false,
      quickViewIsOpen: false
    };
  },

  render() {
    const {kpiId, kpiData, clientIds, onClick, hasAppliedTagFilters, theme} = this.props;
    const {palette} = theme;
    const kpi = kpiRepo.get(kpiId);
    const hasData = kpiData && !kpiData.get("error");
    const name = kpi.get("name");

    const hasClientFilter = clientIds && clientIds.count() > 0;

    if ((hasClientFilter && !kpi.get("filterableByClient")) && hasAppliedTagFilters) {
      return this.renderNotFilterable("tag and client", kpi, theme);
    } else if (hasClientFilter && !kpi.get("filterableByClient")) {
      return this.renderNotFilterable("client", kpi, theme);
    }

    const targetValue = hasData ? kpiData.get("target").value : 0;
    const hasTarget = !hasClientFilter && targetValue > 0 && !hasAppliedTagFilters;

    const hasTargettedData = hasData && hasTarget;

    const isQuickViewVisible = currentClient.hasPermission("QUICKVIEW_CT")
        && Users.currentHasPermission("QUICKVIEW_CT");

    const formattedTotalValue = kpiData && Formatter.format(
        kpiData.get("total"),
        {returnSeparate: true, maxDisplayLength: 6});

    // this is needed because css inline-block removes trailing spaces within our nowrap block
    // https://www.w3.org/TR/CSS22/text.html#white-space-model
    const marginRight = formattedTotalValue && /\s+$/.test(formattedTotalValue.currencySymbol) ? "4px" : "0";
    return (
        <div
            style={this.state.isActive ? {
              ...curveContainerStyle(palette),
              boxShadow: `0 0 0 2px ${hasTargettedData
                  ? getDifferenceFromExpectedColor(kpiData, palette)
                  : palette.background.card}`
            } : curveContainerStyle(palette)}
            onClick={() => onClick(kpiId)}
            onMouseOver={this.handleMouseOver}
            onMouseOut={this.handleMouseOut}>

          <div className="clearfix" style={{width: "100%", lineHeight: 1}}>

            <div css={nameStyle}>
              {name.length > 28 ?
                  <Tooltip
                      text={name}
                      position="top">
                    {name.substring(0, 28)}&hellip;
                  </Tooltip> :
                  name}
            </div>

            {hasTargettedData &&
                <div
                    style={{
                      ...totalStyle,
                      color: valueIsAboveTarget(kpiData) ? palette.success.main : palette.text.main,
                      fontSize: getFormattedTotalValue(kpiData).length > 6 ? "1.1em" : "1.3em",
                      marginRight: `-${marginRight}`
                    }}>
                      <span style={{whiteSpace: "nowrap", display: "inline-block", marginRight}}>
                        {formattedTotalValue.currencySymbol}
                      </span>
                  <span style={{whiteSpace: "nowrap", display: "inline-block", marginRight}}>
                        {formattedTotalValue.numericValue}
                      </span>
                </div>}

          </div>

          {hasTargettedData && <div style={targetStyle}>Target: {Formatter.format(
              getSafely(kpiData, "target"),
              {maxDisplayLength: 6})}</div>}

          {!hasData && <LoadingSpinner customIconStyle={{padding: "50px"}} />}

          {hasTargettedData &&
              <div style={{position: "absolute", top: 66, left: 0, width: "100%"}}>
                <CurveChart
                    theme={theme}
                    key={kpiId}
                    totalValue={kpiData.get("total").value}
                    targetValue={kpiData.get("target").value}
                    expectedValue={numbers.roundTo(kpiData.get("expectedValue").value, 3)}
                    percentageOfTargetComplete={getPercentageOfTargetCompleteString(
                        kpiData.get("total").value,
                        kpiData.get("target").value)} />
              </div>
          }

          {(hasData && !hasTarget) && this.renderNoTarget()}

          <div className="clearfix" style={{width: "100%", position: "absolute", bottom: 0, padding: "0 2em 5px 0"}}>
            {isQuickViewVisible &&
                <div className="right">
                  <QuickViewButton
                      customStyle={{fontSize: "1.25rem"}}
                      onClick={() => this.props.onQuickViewClick(kpiId)} />
                </div>}
            <div className="left" style={{fontSize: "14px", marginTop: "8px"}}>
              {hasTargettedData && this.renderDifferenceFromExpectedText()}
            </div>
          </div>
        </div>
    );
  },
  renderNoTarget() {
    return (
        <div style={noTargetStyle}>
          {getFormattedTotalValue(this.props.kpiData)}
        </div>
    );
  },

  renderNotFilterable(toolTipText, kpi, theme) {
    const name = kpi ? kpi.get("name") : "";
    const {palette} = theme;
    return (
        <Tooltip
            text={`This Metric can't be filtered by ${toolTipText}`}
            position="top">
          <div style={{...curveContainerStyle(theme.palette), background: palette.background.card, opacity: 0.6}}>
            <div className="clearfix" style={{width: "100%", lineHeight: 1}}>
              <div css={nameStyle}>
                {name.length > 40 ? <span>{name.substring(0, 37)}&hellip;</span> : name}
              </div>
            </div>
            <div style={noTargetStyle}>
              {"N/A"}
            </div>
          </div>
        </Tooltip>

    );
  },

  handleMouseOver() {
    this.setState({
      isActive: true
    });
  },

  handleMouseOut() {
    this.setState({
      isActive: false
    });
  },

  renderDifferenceFromExpectedText() {
    const {kpiData, theme} = this.props;
    const expectedValue = numbers.roundTo(getSafely(kpiData, "expectedValue").value, 3);
    if (expectedValue > 0 && kpiData.get("total").value < expectedValue) {
      return (
          <span style={{color: getDifferenceFromExpectedColor(kpiData, theme.palette)}}>
                    <i className="fa fa-caret-down" style={{fontSize: "1.2rem", paddingRight: 5}} />
            {getTotalToExpectedTargetDifference(kpiData)} behind
          </span>);
    }
  }

});

const getFormattedTotalValue = kpiData => {
  const total = getSafely(kpiData, "total");
  return Formatter.format(total, {maxDisplayLength: 6});
};

const getDifferenceFromExpectedColor = (kpiData, palette) => {
  const totalValue = getSafely(kpiData, "total").value;
  const expectedValue = numbers.roundTo(getSafely(kpiData, "expectedValue").value, 3);
  const percentageToExpectedTarget = expectedValue === 0 ? 0 : totalValue / expectedValue;
  let diffColor;
  if (totalValue < expectedValue) {
    if (percentageToExpectedTarget < 0.5) {
      diffColor = palette.error.main;
    } else if (percentageToExpectedTarget >= 0.5 && percentageToExpectedTarget < 1.0) {
      diffColor = palette.warning.main;
    } else if (percentageToExpectedTarget >= 1.0) {
      diffColor = palette.success.main;
    }
  }
  if (totalValue > expectedValue) {
    diffColor = palette.success.main;
  }
  return diffColor;
};

const getTotalToExpectedTargetDifference = kpiData => {
  const totalValue = getSafely(kpiData, "total");
  const expectedValue = numbers.roundTo(getSafely(kpiData, "expectedValue").value, 3);
  const difference = {
    value: Math.abs(totalValue.value - expectedValue),
    currency: totalValue.currency,
    decimalPlaces: totalValue.decimalPlaces
  };
  return Formatter.format(difference, {maxDisplayLength: 6});
};

const valueIsAboveTarget = kpiData => {
  if (kpiData) {
    const targetValue = kpiData.get("target").value;
    const totalValue = kpiData.get("total").value;
    return (totalValue >= targetValue && targetValue > 0);
  } else {
    return false;
  }
};

const getSafely = (kpiData, key) => {
  if (!kpiData.has(key)) {
    return {
      value: 0
    };
  }

  let obj = kpiData.get(key);
  if (!obj.value) {
    obj.value = 0;
  }
  return obj;
};

const curveContainerStyle = (palette) => ({
  position: "relative",
  borderRadius: "5px",
  padding: "1em",
  cursor: "pointer",
  height: "232px",
  transition: "all 0.5s ease",
  background: palette.background.card,
  boxShadow: "0 -1px 3px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)"
});

const nameStyle = css`
  float: left;
  font-size: 0.9em;
  font-weight: 400;
  max-width: 65%;
  position: relative;
  min-height: 1.75rem;
`;

const totalStyle = {
  float: "right",
  borderRadius: 3,
  minWidth: 40,
  maxWidth: "35%",
  textAlign: "right"
};

const targetStyle = {
  fontSize: "13px",
  fontWeight: "normal",
  width: "100%",
  position: "absolute",
  top: 48
};

const noTargetStyle = {
  position: "absolute",
  top: "38%",
  fontSize: "2.5rem",
  width: "94%",
  textAlign: "center"
};

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <PriorityKpiCube theme={theme} {...props} />;
}
