import React from "react";
import moment from "moment";
import {DatePickerInput} from "rc-datepicker";

import Err from "js/common/views/error";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const mysqlFormat = "YYYY-MM-DD";

const labelStyle = {
  fontSize: "0.8rem",
  display: "flex",
  alignItems: "center",
  paddingBottom: 2
};


export default React.memo(({
  style = {},
  onDateChange,
  value,
  placeholder,
  minDate,
  maxDate,
  inlineErrors = false,
  hideError = false,
  isDisabled = false,
  calendarPosition = "left",
  label,
  className = undefined,
  setOpenDatePicker
}) => {
  const datePattern = moment.localeData().longDateFormat("L");
  const [strValue, setStrValue] = React.useState(undefined);
  const {theme} = React.useContext(CustomThemeContext);
  let input;

  const hasValueAndIsRenderable = React.useMemo(() => {
    if (value) {
      if (isInvalidDate(value)) {
        return !!strValue;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }, [value, strValue]);

  return (
      <div
          className={`picker-${theme.themeId} ${className} ${isDisabled && "disabled"}`}
          style={isDisabled ? {pointerEvents: "none", opacity: 0.7, ...style} : style}
          onClick={e => e.stopPropagation()}>
        {label && <label style={labelStyle}>{label}</label>}
        <DatePickerInput
            ref={ref => input = ref}
            className={`c19-react-datepicker picker-${theme.themeId} position-datepicker-${calendarPosition} ${hideError
            &&
            isInvalidDate(value) ? "has-error" : ""}`}
            iconClassName="bhi-calendar"
            displayFormat={datePattern}
            placeholder={placeholder || datePattern}
            minDate={minDate ? minDate.format(mysqlFormat) : null}
            maxDate={maxDate ? maxDate.format(mysqlFormat) : null}
            onShow={() => setOpenDatePicker && setOpenDatePicker(input)}
            onChange={(date, dateStr) => {
              // date, dateStr are both returned by rc-datepicker as "Invalid date" when DatePickerInput is
              // manually cleared by user, hence the use of input.state.dateString as an ugly workaround
              setStrValue(input.state.dateString);
              return onDateChange(parseDate(dateStr), input.state.dateString);
            }}
            value={hasValueAndIsRenderable ? value.format(mysqlFormat) : null} />
        {(!hideError && isInvalidDate(value))
            && <Err
                text={`Please enter a valid date (format: ${datePattern})`}
                style={inlineErrors ? {position: "absolute", bottom: -45} : {margin: "0.5rem 0"}} />}
      </div>);
});

const parseDate = dateStr => moment(dateStr, mysqlFormat);

const isInvalidDate = date => date && !date.isValid();
