import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";

const getAdvancedTrendSeriesName = (rootTrendName, whatToPlot, howToPlot) => {
    switch (whatToPlot) {
        case "value":
            return rootTrendName;
        case "target":
            return `${rootTrendName} - Target`;
        case "percentage-target":
            return `${rootTrendName} - ${getPercentageOfTargetTypeDisplayName(howToPlot)}`;
        case "average-by-user":
            return `${rootTrendName} - Average by user`;
        default:
            throw new Error("Unsupported whatToPlot value:", whatToPlot);
    }
};

const getPercentageOfTargetTypeDisplayName = howToPlot => {
    switch (howToPlot) {
        case "DAILY_TARGET":
            return "% of Daily Target";
        case "TOTAL_TARGET":
            return "% of Total Target";
        case "EXPECTED_TARGET":
            return "% of Expected Target";
        default:
            throw new Error("Unsupported howToPlot value:", howToPlot);
    }
};

const getKpiTrendName = (kpiId, whatToPlot, qualifierType, qualifierId) => {
    const kpi = kpiRepo.get(kpiId);
    if (whatToPlot === "percentage-target") {
        return kpi.get("name");
    }
    const kpiValueFormat = kpi.get("valueFormat");
    if (kpiValueFormat !== "CURRENCY") {
        return kpi.get("name");
    }
    const group = qualifierType === "USER" ? getUserGroup(qualifierId) : Groups.getGroup(qualifierId);
    return `${kpi.get("name")} (${group.get("currencyCode")})`;
};

const getUserGroup = userId => {
    const user = Users.getUser(userId);
    return Groups.getGroup(user.get("nearestGroupId"));
};

export {
    getAdvancedTrendSeriesName,
    getPercentageOfTargetTypeDisplayName,
    getKpiTrendName
};
