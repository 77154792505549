const calculateClientFlashTime = (serverTimestampOfFlashInMs,
    clientTimestampOfRequestTransmissionInMs,
    serverTimestampOfRequestReceptionInMs,
    serverTimestampOfResponseTransmissionInMs,
    clientTimestampOfResponseReceptionInMs) => {
  // The calculation below is based on the algorithm described here: https://en.wikipedia.org/wiki/Network_Time_Protocol#Clock_synchronization_algorithm

  const offset = ((serverTimestampOfRequestReceptionInMs - clientTimestampOfRequestTransmissionInMs) +
      (serverTimestampOfResponseTransmissionInMs - clientTimestampOfResponseReceptionInMs)) / 2;

  return serverTimestampOfFlashInMs - offset;
}

export {
  calculateClientFlashTime
};