import React from "react";
import Immutable from "immutable";

import * as KpiRepo from "js/common/repo/backbone/kpi-repo";
import {indexBy} from "js/common/utils/collections";

import Chip from "@mui/material/Chip";
import KpiPicker from "js/common/views/kpis/custom-kpi-picker";
import DataConfigToolbar from "js/dashboards/data-config-toolbar";

const GridEditor = React.memo(({component, onComponentChange}) => {
  const config = component.get("dataConfig");

  const onConfigChange = React.useCallback(newDataConfig => {
    onComponentChange(component.set("dataConfig", newDataConfig));
  }, [onComponentChange, component]);

  const allVisibleKpis = React.useMemo(
      () => Immutable.fromJS(KpiRepo
          .getAll()
          .toJSON())
          .filter(k => k.get("visible") || config.get("kpiIds").includes(k.get("id"))),
      [config]);

  const idToKpi = React.useMemo(() => indexBy(k => k.get("id"), allVisibleKpis), [allVisibleKpis]);

  const selectedKpiIds = config.get("kpiIds", Immutable.Set()).toSet();
  const selectableKpis = React.useMemo(() => allVisibleKpis.filter(k => !selectedKpiIds.has(k.get("id"))), [allVisibleKpis, selectedKpiIds]);

  const selectedKpis = selectedKpiIds.map(id => idToKpi.get(id));
  const groupingEntitiesToFilterBy = selectedKpis.flatMap(kpi => kpi.get("groupingEntitiesToFilterBy"));

  const transactionReportEnabled = !config
      .getIn(["cellDialog", "components"], Immutable.List())
      .filter(c => c.get("title") === "Transaction Master")
      .isEmpty();

  const containsPayBillEntities = selectedKpis.map(kpi => kpi.get("rootDomainEntity"))
      .some(e => payBillEntities.includes(e));

  const onRemoveKpi = React.useCallback(kpi => {
    if(transactionReportEnabled
        && containsPayBillEntities
        && selectedKpis.remove(kpi).every(kpi => !payBillEntities.includes(kpi.get("rootDomainEntity")))) {
      onConfigChange(config
          .update("kpiIds", kpiIds => kpiIds.toSet().remove(kpi.get("id")))
          .updateIn(["cellDialog", "components"], Immutable.List(), components => components.filter(c => c.get("title") !== "Transaction Master"))
          .setIn(["cellDialog", "entitiesToFilterBy"], Immutable.List()))

    } else {
      onConfigChange(config.update("kpiIds", kpiIds => kpiIds.toSet().remove(kpi.get("id"))))
    }
  }, [config, containsPayBillEntities, onConfigChange, selectedKpis, transactionReportEnabled]);

  return <div style={{padding: "0.5rem"}}>
    <div style={{display: "flex", columnGap: "0.3rem", rowGap: "0.3rem", flexWrap: "wrap", padding: "0.3rem"}}>
      {selectedKpiIds
          .map(kpiId => idToKpi.get(kpiId))
          .sortBy(kpi => kpi.get("name"))
          .map(kpi => <Chip
              key={kpi.get("id")}
              label={kpi.get("name")}
              onDelete={() => onRemoveKpi(kpi)} />)}
    </div>

    <div style={{width: "50%", marginBottom: "0.3rem"}}>
      <KpiPicker
          kpis={selectableKpis}
          onKpiSelect={kpiId => onConfigChange(config.update("kpiIds", kpiIds => kpiIds.toSet().add(kpiId)))}
          label="Add Metrics"
          closeOnSelect={false} />
    </div>

    <DataConfigToolbar config={config} onConfigChange={onConfigChange} />

    {containsPayBillEntities && <TransactionReportSettings
        config={config}
        onConfigChange={onConfigChange}
        groupingEntitiesToFilterBy={groupingEntitiesToFilterBy}
        selectedKpis={selectedKpis}
        allVisibleKpis={allVisibleKpis}
        transactionReportEnabled={transactionReportEnabled}
        idToKpi={idToKpi}
    />}
  </div>;
});

const TransactionReportSettings = React.memo(({
                                                  config,
                                                  onConfigChange,
                                                  groupingEntitiesToFilterBy,
                                                  allVisibleKpis,
                                                  transactionReportEnabled,
                                                  idToKpi
                                              }) => {
    const transactionReportIndex = config.getIn(["cellDialog", "components"], Immutable.List()).findKey(c => c.get("title") === "Transaction Master")
    const selectedTransactionKpiIds = config
        .getIn(["cellDialog", "components", transactionReportIndex, "dataConfig", "kpiIds"], Immutable.List());

    const availableTransactionKpis = allVisibleKpis
        .filter(kpi => transactionEntities.includes(kpi.get("rootDomainEntity")))
        .filter(kpi => !selectedTransactionKpiIds.includes(kpi.get("id")));

    return <div style={{marginTop: 20}}>
        <div style={{fontSize: "0.8rem", marginLeft: 10}}>Choose the transaction Metric(s) you'd like displayed in the
            Transaction Report click through.
        </div>
        <div style={{display: "flex", columnGap: "0.3rem", rowGap: "0.3rem", flexWrap: "wrap", padding: "0.3rem"}}>
            {selectedTransactionKpiIds
                .map(kpiId => idToKpi.get(kpiId))
                .sortBy(kpi => kpi.get("name"))
                .map(kpi => <Chip
                    key={kpi.get("id")}
                    label={kpi.get("name")}
                    onDelete={() => {
                        handleRemoveTransactionKpi(kpi.get("id"), config, onConfigChange)
                    }}/>)}
        </div>
        <div style={{width: "50%"}}>
        <KpiPicker
            kpis={availableTransactionKpis}
            onKpiSelect={kpiId => handleAddTransactionKpi(kpiId, transactionReportEnabled, onConfigChange, config, groupingEntitiesToFilterBy)}
            label="Choose Transaction Metrics"
            closeOnSelect={false}/>
        </div>
    </div>
});

const handleAddTransactionKpi = (kpiId, transactionReportEnabled, onConfigChange, config, entitiesToFilterBy) => {
    if (transactionReportEnabled) {
        const transactionReportIndex = config.getIn(["cellDialog", "components"]).findKey(c => c.get("title") === "Transaction Master")
        onConfigChange(config.updateIn(["cellDialog", "components", transactionReportIndex, "dataConfig", "kpiIds"], kpiIds => kpiIds.toSet().add(kpiId)))
    } else {
        onConfigChange(config
            .updateIn(["cellDialog", "components"], Immutable.List(), components => components.push(generateTransactionMaster(kpiId)))
            .setIn(["cellDialog", "entitiesToFilterBy"], entitiesToFilterBy));
    }
};

const handleRemoveTransactionKpi = (kpiId, config, onConfigChange) => {
    const transactionReportIndex = config.getIn(["cellDialog", "components"]).findKey(c => c.get("title") === "Transaction Master");
    const selectedTransactionKpis = config.getIn(["cellDialog", "components", transactionReportIndex, "dataConfig", "kpiIds"]);

    if (selectedTransactionKpis.size === 1) {
        onConfigChange(config
            .updateIn(["cellDialog", "components"], components => components.filter(c => c.get("title") !== "Transaction Master"))
            .setIn(["cellDialog", "entitiesToFilterBy"], Immutable.List()));
    } else {
        onConfigChange(config.updateIn(["cellDialog", "components", transactionReportIndex, "dataConfig", "kpiIds"], kpiIds => kpiIds
            .toSet()
            .remove(kpiId)))
    }
};

const generateTransactionMaster = kpiId => {
    return Immutable.fromJS({
        title: "Transaction Master",
        type: "UiGrid",
        dataConfig:
            {
                inheritKeys: ["timeframe", "qualifier", "tagFilter", "clientFilter"],
                kpiIds: [kpiId],
                gridProps: {pivotMode: false},
                showAllColumns: true,
                isClickThrough: true
            },
        layout: {width: 48, height: 12, x: 0, y: 0}
    })
};

const payBillEntities = Immutable.List([
    "BILLABLE_CHARGE",
    "BILLABLE_CHARGE_SPLIT",
    "BILL_MASTER_TRANSACTION",
    "BILL_MASTER_TRANSACTION_SPLIT",
    "PAYABLE_CHARGE",
    "PAYABLE_CHARGE_SPLIT",
    "PAY_MASTER_TRANSACTION",
    "PAY_MASTER_TRANSACTION_SPLIT",
    "INVOICE_STATEMENT",
    "INVOICE_STATEMENT_SPLIT"]);

const transactionEntities = Immutable.List([
    "BILL_MASTER_TRANSACTION",
    "BILL_MASTER_TRANSACTION_SPLIT",
    "PAY_MASTER_TRANSACTION",
    "PAY_MASTER_TRANSACTION_SPLIT"
]);

export default GridEditor;
