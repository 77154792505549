import React from "react";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";

import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as numbers from "js/common/utils/numbers";
import * as Users from "js/common/users";
import * as Formatter from "js/common/utils/formatter";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const rowsPerPage = 10;
const maxCharDisplayLength = 10;

const CurrentLeaderboard = createReactClass({

    mixins: [PureRenderMixin],

    getInitialState() {
        return {
            displayPercentOfTarget: false
        };
    },

    componentDidMount() {
        if (this.props.displayTotalAndPercentOfTarget) {
            this.displayPercentOfTargetIntervalId = window.setInterval(() => {
                this.setState({
                    displayPercentOfTarget: !this.state.displayPercentOfTarget
                });
            }, 4500);
        }
    },

    componentWillUnmount() {
        window.clearInterval(this.displayPercentOfTargetIntervalId);
    },

    render() {
        return (
            <div style={{ height: "100%" }}>
                {this.renderLeaderboardHeaderRow()}
                {this.renderLeaderboardDataRows()}
            </div>
        );
    },

    renderLeaderboardHeaderRow() {
        const { rankedKpiColumnHeading, otherKpiColumnHeading, otherKpiId, theme } = this.props;
        const headerRowStyle = theme => ({
            ...headerTextStyle(theme),
            height: "6vh",
            display: "flex"
        });
        const cellStyle = {
            alignSelf: "flex-end",
            textAlign: "center",
            paddingLeft: "1.5vmin",
            paddingRight: "1.5vmin",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
        };
        const rankedKpiColWidth = otherKpiId ? "15vw" : "25vw";
        const otherKpiColWidth = "15vw";
        return (
            <div style={headerRowStyle(theme)}>
                <div style={{ ...cellStyle, width: otherKpiId ? "10vw" : "12vw" }}>
                    Rank
                </div>
                <div style={{ ...cellStyle, textAlign: "left", width: otherKpiId ? "30vw" : "60vw" }}>
                    Name
                </div>
                {otherKpiId &&
                    <div style={{ ...cellStyle, width: otherKpiColWidth }}>{otherKpiColumnHeading}</div>}
                <div style={{ ...cellStyle, width: rankedKpiColWidth }}>
                    {rankedKpiColumnHeading}
                </div>
            </div>
        );
    },

    renderLeaderboardDataRows() {
        const {
            rankedKpiId,
            otherKpiId,
            pageRows,
            currentTopPerformerIndex,
            highlightCurrentTopPerformerRow
        } = this.props;
        const rankedKpiConfig = kpiRepo.get(rankedKpiId);
        const rankedKpiValueFormat = rankedKpiConfig.get("valueFormat");
        const otherKpiConfig = kpiRepo.get(otherKpiId);
        const otherKpiValueFormat = otherKpiConfig ? otherKpiConfig.get("valueFormat") : null;
        const rowsContainerStyle = {
            display: "flex",
            flexDirection: "column",
            height: `calc(100% - 6vh)`,
            borderTop: "3px solid #f9ec33",
            ...textStyle
        };
        const cellStyle = {
            textAlign: "center",
            paddingLeft: "1vmin",
            paddingRight: "1vmin",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontSize: "4.25vmin",
            lineHeight: 1.75
        };
        return (
            <div style={rowsContainerStyle}>
                {pageRows.map((row, index) => {
                    const userId = row.get("userId");
                    const user = Users.getUser(userId);
                    const isRowHighlighted = highlightCurrentTopPerformerRow && currentTopPerformerIndex === index;
                    const isLastRow = index === pageRows.count() - 1;
                    const rowStyle = {
                        flexGrow: pageRows.count() === rowsPerPage ? 1 : null,
                        display: "flex",
                        flexDirection: "row",
                        background: isRowHighlighted ? highlightedRowBackground : defaultRowBackground,
                        borderBottom: isLastRow ? "3px solid #f9ec33" : "2px solid #5a5185",
                        color: isRowHighlighted ? highlightedRowTextColor : textStyle.color,
                        textShadow: isRowHighlighted ? highlightedRowTextShadow : textStyle.textShadow
                    };
                    let rankedKpiCellBackground = lastColumnCellBackground;
                    if (isRowHighlighted) {
                        rankedKpiCellBackground = highlightedRowBackground;
                    } else if (otherKpiId) {
                        rankedKpiCellBackground = "linear-gradient(to bottom, #1a125d 0%, #1a125d 23%, #1b1342 100%)";
                    }
                    return (
                        <div key={row.get("userId")} style={rowStyle}>
                            <div style={{
                                ...cellStyle,
                                width: otherKpiId ? "10vw" : "12vw",
                                color: isRowHighlighted ? highlightedRowTextColor : "#f9ec33",
                                textShadow: isRowHighlighted ? highlightedRowTextShadow : textStyle.textShadow
                            }}>
                                {row.get("rank")}
                            </div>
                            <div style={{ ...cellStyle, textAlign: "left", width: otherKpiId ? "30vw" : "60vw" }}>
                                {user.get("fullName")}
                            </div>
                            {otherKpiId &&
                                <div style={{
                                    ...cellStyle,
                                    width: "15vw",
                                    background: isRowHighlighted ? highlightedRowBackground : lastColumnCellBackground
                                }}>
                                    {this.getOtherKpiDisplayValue(row.get("otherKpi"), otherKpiValueFormat)}
                                </div>}
                            <div style={{
                                ...cellStyle,
                                width: otherKpiId ? "15vw" : "25vw",
                                background: rankedKpiCellBackground
                            }}>
                                {this.getRankedKpiDisplayValue(row.get("rankedKpi"), rankedKpiValueFormat)}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    },

    getRankedKpiDisplayValue(rankedKpi, rankedKpiValueFormat) {
        const rankedKpiTotal = rankedKpi.get("total");
        if (this.state.displayPercentOfTarget) {
            const rankedKpiTarget = rankedKpi.get("target");
            const rankedKpiHasTarget = rankedKpiTarget.get("value") > 0;
            if (rankedKpiHasTarget) {
                return numbers.toPercentageStr(rankedKpiTotal.get("value") / rankedKpiTarget.get("value"));
            } else {
                return "0%";
            }
        } else {
            return Formatter.format(rankedKpiTotal.toJS(), {valueFormat: rankedKpiValueFormat, maxDisplayLength: maxCharDisplayLength})
        }
    },

    getOtherKpiDisplayValue(otherKpi, otherKpiValueFormat) {
        const otherKpiTotal = otherKpi.get("total");
        if (this.state.displayPercentOfTarget) {
            const otherKpiTarget = otherKpi.get("target");
            const otherKpiHasTarget = otherKpiTarget.get("value") > 0;
            if (otherKpiHasTarget) {
                return numbers.toPercentageStr(otherKpiTotal.get("value") / otherKpiTarget.get("value"));
            } else {
                return "No Target";
            }
        } else {
            return Formatter.format(otherKpiTotal.toJS(), {valueFormat: otherKpiValueFormat, maxDisplayLength: maxCharDisplayLength})
        }
    }

});

const defaultRowBackground = "linear-gradient(to bottom, #060051 0%, #060051 23%, #09012b 100%)";
const lastColumnCellBackground = "linear-gradient(to bottom, #221f66 0%, #221f66 23%, #23204a 100%)";
const highlightedRowBackground = "linear-gradient(to bottom, #f9ec33, #ac9107)";
const highlightedRowTextColor = "#222";
const highlightedRowTextShadow = "0 1px 0 #f3f3f3";

const textStyle = (theme) => ({
    fontFamily: theme.typography.fontFamilyBold,
    fontSize: "3.5vmin",
    color: "#fff",
    textShadow: "0 2px 2px #000"
});

const headerTextStyle = theme => ({
    ...textStyle(theme),
    fontSize: "3vh",
    color: "#f9ec33",
    textTransform: "uppercase",
    lineHeight: 1
});


export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <CurrentLeaderboard theme={theme} {...props}  />
};
