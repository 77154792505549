import store from "store";
import traverse from "traverse";

import {apiUrl} from "js/app-configuration";
import eventBus from "js/cube19.event-bus";
import * as Auditor from "js/common/auditer";
import buildInfo from "js/build-info";

const Backbone = window.Backbone;
const $ = window.$;
const path = window.path;

const RATE_LIMITED = 429;
const UNAUTHORISED = 401;
const FORBIDDEN = 403;

const delayCap = 16;
const baseDelay = 1;
const rateLimitMaxRetries = 5;
const retryAfterMaxRetries = 5;

const DEFAULT_AJAX_OPTIONS = {
  processData: true,
  cache: false,
  relativeToAppLayer: true,
  fatalError: false,
  autoLogout: true,
  retryOnRateLimit: true,
  rateLimitCount: 0,
  retryAfterCount: 0,
  xhrFields: {withCredentials: true}
};

Backbone.ajax = function(ajaxOptions) {
  return request(ajaxOptions);
};

function get(ajaxOptions) {
  ajaxOptions.type = "GET";
  return request(ajaxOptions);
}

function put(ajaxOptions) {
  ajaxOptions.type = "PUT";
  return request(ajaxOptions);
}

function post(ajaxOptions) {
  ajaxOptions.type = "POST";
  return request(ajaxOptions);
}

function del(ajaxOptions) {
  ajaxOptions.type = "DELETE";
  return request(ajaxOptions);
}

function isCubeAvailable() {
  return request({relativeToAppLayer: false, url: window.location.href, fatalError: true});
}

const leftAngleBracket = new RegExp("<", "g");
const rightAngleBracket = new RegExp(">", "g");
const balancedAngleBrackets = new RegExp("<\/?\w+>", "g");
const escapeResponseData = res => {
  if (res) {
    traverse(res).forEach(function(val) {
      if (this.isLeaf && typeof (val) === "string" && balancedAngleBrackets.test(val)) {
        const safe = val
            .replace(leftAngleBracket, "&lt;")
            .replace(rightAngleBracket, "&gt;");
        this.update(safe);
      }
    });
  }
  return res;
};

const appendQueryParamToUrl = (url, key, value) => {
  let delimiter;
  if (url.indexOf("?") !== -1) {
    delimiter = "&";
  } else {
    delimiter = "?";
  }
  return url + delimiter + key + "=" + value;
};

const smartTvWorkaround = url => {
  if (window.location.search.indexOf("smart-tv-workaround") !== -1) {
    return appendQueryParamToUrl(url, "smart-tv-workaround", "true");
  } else {
    return url;
  }
};

const enableTracing = (options) => {
  const traceType = options.trace === "NONE" ? false : options.trace;
  if (window.cube19Tracing || traceType) {
    return appendQueryParamToUrl(options.url, "__cube19Tracing__", window.cube19Tracing || traceType || true);
  } else {
    return options.url;
  }
};

// TODO: store each xhr from `$.ajax(options)` so we can call abort() on it
function request(originalOptions) {
  const options = {...DEFAULT_AJAX_OPTIONS, ...originalOptions};

  if (options.relativeToAppLayer) {
    options.url = apiUrl + path("/ApplicationLayer", options.url);
    options.relativeToAppLayer = false;
  }

  options.url = smartTvWorkaround(options.url);
  options.url = enableTracing(options);

  if (options.json) {
    options.data = JSON.stringify(options.json);
    options.processData = false;
    options.contentType = "application/json";
    delete options.json;
  }

  options.headers = {
    ...options.headers,
    "X-CUBE19-EXPECTED-CLIENT-ID": window.expectedClientId,
    "CUBE19-TIMEZONE-OFFSET-MILLIS": -(new Date().getTimezoneOffset() * 60_000),
    "CUBE19-UI-VERSION": buildInfo.buildId,
    "CUBE19-DEVICE-ID": store.get("deviceId")
  };

  return new Promise(
      (resolve, reject) => {
        // Callbacks from options are used by Backbone
        const oldSuccess = options.success;
        const oldError = options.error;

        options.success = (body, statusText, xhr) => {
          if (isAppUnderMaintenance(xhr)) {
            eventBus.trigger("app-under-maintenance");
            reject(xhr);
            oldError?.(xhr, "error", new Error("The application is under maintenance"));
          } else {
            const escapedBody = escapeResponseData(body);
            resolve({xhr, body: escapedBody});
            oldSuccess?.(escapedBody, statusText, xhr);
          }
        };
        options.error = (xhr, status, errorThrown) => {
          reject(xhr);
          oldError?.(xhr, status, errorThrown);
        };

        $.ajax(options);
      })
      .then(
          ({xhr, body}) => {
            if (options.returnFileName) {
              let fileName = "";
              const disposition = xhr.getResponseHeader("Content-Disposition");
              if (disposition && disposition.indexOf("attachment") !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(disposition);
                if (matches?.[1]) {
                  fileName = matches[1].replace(/['"]/g, "");
                }
              }
              return {fileName, body};
            } else if (options.returnXhr) {
              return {xhr, body};
            } else {
              return body;
            }
          },
          xhr => {
            if (isAppUnderMaintenance(xhr)) {
              return;
            } else if (isAppDown(xhr)) {
              // TODO notify user that app is down and that they should wait
              // TODO poll app layer until back up, then use session check + remembered login and close popup
              eventBus.trigger("error:fatal");
            } else if (xhr.getResponseHeader("Retry-After")) {
              if (options.retryAfterCount >= retryAfterMaxRetries) {
                console.log("retried too many times, giving up on request for " + options.url);
                Auditor.audit("retry-after:too-many-retries", {endpoint: options.url});
                throw xhr;
              } else {
                options.retryAfterCount++;
                return delay(xhr.getResponseHeader("Retry-After") * 1000).then(() => request(options));
              }
            } else if (isBlankElbPage(xhr)) {
              // TODO notify user that app is down and that they should wait
              // TODO poll app layer until back up, then use session check + remembered login and close popup
              eventBus.trigger("error:fatal");
            } else if (xhr.status === UNAUTHORISED && options.autoLogout) {
              console.log("received unauthorised response, reloading page");
              window.location.reload(true);
            } else if (xhr.status === FORBIDDEN) {
              if (xhr.responseJSON.type === "INVALID_IP" && options.autoLogout) {
                if (store.enabled) {
                  store.set("ipRestricted", true);
                }
                window.location.reload(true);
              } else {
                throw xhr;
              }
            } else if (xhr.status === RATE_LIMITED) {
              if (!options.retryOnRateLimit) {
                console.log("rate-limited but told not to retry this request automatically");
                throw xhr;
              } else if (options.rateLimitCount >= rateLimitMaxRetries) {
                console.log("rate limited too many times, giving up on request for " + options.url);
                Auditor.audit("rate-limit:too-many-retries", {endpoint: options.url});
                throw xhr;
              } else {
                options.rateLimitCount++;
                const cappedExponentialDelay = Math.min(delayCap, baseDelay * (2 ** options.rateLimitCount));
                const fullJitterDelay = Math.random() * cappedExponentialDelay;
                return delay(fullJitterDelay * 1000).then(() => request(options));
              }
            } else {
              if (options.fatalError) {
                eventBus.trigger("error:fatal", xhr);
              }
              throw xhr;
            }
          });
}

const delay = (millis, value) => new Promise((resolve) => {
  setTimeout(() => resolve(value), millis);
});

const isBlankElbPage = xhr => xhr.status === 503;
const isAppUnderMaintenance = xhr => !!xhr.getResponseHeader("X-CUBE19-MAINTENANCE");
const isAppDown = xhr => !!xhr.getResponseHeader("X-CUBE19-APP-DOWN");

export {
  request,
  get,
  put,
  post,
  del,
  isCubeAvailable
};
