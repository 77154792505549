import React from "react";
import Immutable from "immutable";
import moment from "moment";

import * as Users from "js/common/users";
import * as Groups from "js/common/groups";
import * as Ajax from "js/common/ajax";
import * as Branding from "js/common/branding-constants";
import * as KpiRepo from "js/common/repo/backbone/kpi-repo";
import * as Rata from "js/common/utils/remote-data";
import useBooleanState from "js/common/hooks/use-boolean-state";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

import RecalculateGroupTarget from "js/admin/targets/recalculate-group-target";
import TargetRecalculationJobs from "js/admin/targets/target-recalculation-jobs";
import {TextButton} from "js/common/views/inputs/buttons";
import Dialog from "js/common/views/dialog";

const getDefaultConfig = () => Immutable.fromJS({
  priorityFlag: "NONE",
  groupPercentage: 100,
  groupTargetStartDate: moment(),
  includeInvisibleUsers: false,
  groupId: Users.getCurrentUser().get("groupId"),
  kpiIds: Immutable.Set()
});

export default React.memo(() => {
  const {theme} = React.useContext(CustomThemeContext);
  const kpis = React.useMemo(() => getTargetableKpis(), []);

  const [errorMessage, setErrorMessage] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [config, setConfig] = React.useState(getDefaultConfig());

  const {flag: isDialogOpen, setTrue: openDialog, setFalse: closeDialog} = useBooleanState();
  const [isStartingCalculation, setStartingCalculation] = React.useState(false);

  const [jobsWrapper, setJobsWrapper] = React.useState(Rata.wrapInitialValue(Immutable.List()));
  const [selectedJobId, setSelectedJobId] = React.useState(null);

  const loadAndSetJobs = React.useCallback(() => {
    setJobsWrapper(Rata.toLoading);
    loadTargetRecalculationJobs().then(newJobs => setJobsWrapper(x => Rata.toLoaded(x, newJobs)));
  }, []);

  // Populate table on page load
  React.useEffect(() => {
    loadAndSetJobs();
  }, []);

  // Poll every 3 seconds for job status changes
  // This is how the page used to work before it was moved into a new tab
  React.useEffect(() => {
    const interval = setInterval(() => {
      loadTargetRecalculationJobs().then(newJobs => setJobsWrapper(x => Rata.toLoaded(x, newJobs)));
    }, 3000);
    return () => clearInterval(interval)
  }, []);

  const handleConfigChange = React.useCallback(updatedConfig => {
    if (!updatedConfig.get("groupTargetStartDate").isValid()) {
      setErrorMessage("Target start date required. See highlighted error above.");
    } else {
      setErrorMessage(null);
    }
    setConfig(updatedConfig);
  }, []);

  const handleCancelConfigChanges = React.useCallback(() => {
    setConfig(getDefaultConfig());
    setErrorMessage(null);
  }, []);


  const startCalculation = React.useCallback(() => {
    setErrorMessage(null);
    setStartingCalculation(true);

    const loadingFactor = config.get("groupPercentage") / 100;
    const requestParams = {
      targetStart: formatDate(config.get("groupTargetStartDate"), sqlDateFormat),
      priorityKpiFlag: config.get("priorityFlag"),
      loadingFactor,
      excludeInvisibleUsers: !config.get("includeInvisibleUsers"),
      startingGroupId: config.get("groupId"),
      kpiIds: config.get("kpiIds").toJS()
    };
    calculateGroupTargets(requestParams).then(
        () => {
          setConfig(getDefaultConfig());
          setStartingCalculation(false);
          closeDialog();
          setSuccessMessage("Target recalculation started");
          loadAndSetJobs();
        },
        () => {
          setStartingCalculation(false);
          closeDialog();
          setErrorMessage(`An unexpected error has occurred. ${Branding.submitTicketString}`);
        });
  }, [config, closeDialog, loadAndSetJobs]);

  return <>
    <RecalculateGroupTarget
        kpis={kpis}
        config={config}
        onConfigChange={handleConfigChange}
        onCancelConfigChangesRequest={handleCancelConfigChanges}
        onCalculateGroupTargetsRequest={openDialog}
        error={errorMessage}
        success={successMessage}
        onSuccessMessageTimeout={() => setSuccessMessage(null)} />
    <ConfirmRecalculateGroupTargetsDialog
        theme={theme}
        isOpen={isDialogOpen}
        groupId={config.get("groupId")}
        startDate={config.get("groupTargetStartDate")}
        isActionsDisabled={isStartingCalculation}
        onCloseRequest={closeDialog}
        onConfirmRequest={startCalculation} />
    <TargetRecalculationJobs
        isLoading={Rata.isLoading(jobsWrapper)}
        jobs={Rata.getValue(jobsWrapper)}
        onJobSelect={setSelectedJobId}
        selectedJobId={selectedJobId} />
  </>;
});

const ConfirmRecalculateGroupTargetsDialog = React.memo(({
  isOpen,
  groupId,
  startDate,
  onCloseRequest,
  onConfirmRequest,
  theme
}) => {
  const actions = [
    <TextButton
        key="cancel-grp-targets-recalc"
        label="Cancel"
        style={buttonSpacing}
        onClick={onCloseRequest} />,
    <TextButton
        key="confirm-grp-targets-recalc"
        type="primary"
        label="Yes"
        style={buttonSpacing}
        onClick={onConfirmRequest} />
  ];
  const startDateLabel = startDate.format("ll").replace(",", "");
  const groupName = Groups.getGroup(groupId).get("name");
  return (
      <Dialog
          paperStyle={{
            backgroundColor: theme.themeId === "light" ? theme.palette.background.card : theme.palette.background.paper
          }}
          title="Recalculate Targets"
          actions={actions}
          actionsContainerStyle={dialogButtonContainerStyle}
          titleStyle={dialogHeaderStyle(theme)}
          bodyStyle={dialogBodyStyle(theme)}
          autoDetectWindowHeight={true}
          onRequestClose={onCloseRequest}
          open={isOpen}>
        <p>This will replace any Group targets you have set on or after the given start date
          of <strong>{startDateLabel}</strong> for <strong>{groupName}</strong>.</p>
        <p>{`Are you sure you want to recalculate the targets set for ${groupName}?`}</p>
      </Dialog>
  );
});

const dialogHeaderStyle = theme => ({
  color: theme.palette.primary.main,
  fontSize: "1rem"
});

const dialogBodyStyle = theme => ({
  overflow: "visible",
  color: theme.palette.textColor
});

const dialogButtonContainerStyle = {
  paddingLeft: "2rem",
  paddingRight: "2rem",
  paddingBottom: "2rem"
};

const buttonSpacing = {
  marginLeft: "0.5rem",
  marginRight: "0.5rem"
};

const sqlDateFormat = "YYYY-MM-DD";

const formatDate = (momentDate, pattern = "L") => momentDate.format(pattern);

const calculateGroupTargets = requestParams => Ajax.post({
  url: "targets/targets-calculation",
  json: requestParams
});

const loadTargetRecalculationJobs = () => Ajax
    .get({url: "targets/recalculation-jobs"})
    .then(results => Immutable.fromJS(results))
    .then(jobs => jobs.map(parseJobPayload));

const parseJobPayload = job => job.set("payload", JSON.parse(job.get("payload")));

const getTargetableKpis = () => {
  const allKpis = Immutable.fromJS(KpiRepo.getAll().toJSON());
  return allKpis.filter(kpi => kpi.get("visible") && kpi.get("targetable"));
};

