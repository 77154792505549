import React from "react";
import moment from "moment";
import Immutable from "immutable";
import {Radio} from "@mui/material";

import * as RatioRepo from "js/common/repo/ratio-repo";
import * as KpiRepo from "js/common/repo/backbone/kpi-repo";
import * as KpiCalculator from "js/common/kpi-calculator";
import * as DashboardUtils from "js/dashboards/utils";
import * as TimeframeRepo from "js/common/repo/backbone/timeframe-repo";

import RatioPicker from "js/common/views/inputs/ratio-picker";
import RatioPanel from "js/common/views/ratio-panel";
import CustomRatioMaker from "js/dashboards/components/ratios/custom-ratio-maker";
import DataConfigToolbar from "js/dashboards/data-config-toolbar";
import * as Overlays from "js/dashboards/components/overlays";
import RatioDetailsDialog from "js/oneview/ratio-details/dialog";
import KpiDetailsDialog from "js/oneview/kpi-details/dialog";
import {renderUserOrGroupDetails} from "js/dashboards/utils";

const Ratio = React.memo(({
  dimensions,
  data,
  config,
  onRequestDialog,
  onParentConfigChange,
  componentId
}) => {
  const firstKpi = data.get("value")[0];
  const secondKpi = data.get("value")[1];
  if (!firstKpi || !secondKpi) {
    return <Overlays.Message coverOtherContent={false}>
      Choose a Ratio
    </Overlays.Message>;
  } else {
    return <RatioPanel
        config={config}
        componentId={componentId}
        ratioData={Immutable.Map({
          firstKpiData: Immutable.Map(data.get("value")[0]),
          secondKpiData: Immutable.Map(data.get("value")[1])
        })}
        clientIds={config.getIn(["clientFilter", "allClientIds"], Immutable.List())}
        currentTimeframe={TimeframeRepo.parse(config.get("timeframe").toJS())}
        onClick={() => renderDialog(config, data, onParentConfigChange, onRequestDialog)}
    />;
  }
});

const RatioEditor = React.memo(({
  component,
  onComponentChange
}) => {
  const config = component.get("dataConfig");

  const onConfigChange = React.useCallback(newDataConfig => {
    onComponentChange(component.set("dataConfig", newDataConfig));
  }, [onComponentChange, component]);

  const [newRatioSelected, setNewRatioSelected] = React.useState(config.get("type") === "custom");

  const kpis = React.useMemo(
      () => Immutable.fromJS(KpiRepo
          .getAll().toJSON())
          .filter(k => k.get("visible") || k.get("id") === config.get("firstKpiId")),
      [config]);

  const getRatios = () => RatioRepo.getAll();

  return <div style={{padding: "0.5rem"}}>
    <DataConfigToolbar config={config} onConfigChange={onConfigChange} />

    <div style={{marginTop: "1rem", marginBottom: "0.5rem", display: "flex", alignItems: "flex-start"}}>
      <Radio
          checked={!newRatioSelected} onChange={() => {
        onConfigChange(config.set("type", "global"));
        setNewRatioSelected(!newRatioSelected);
      }} />
      <div style={{paddingTop: "0.7rem"}}>
        <h3 style={{fontSize: 14}}>Choose an existing ratio</h3>
        <div
            data-test-id="ratio-picker"
            style={{width: 300, opacity: newRatioSelected ? 0 : 1}}>
          <RatioPicker
              label={config.get("selectedRatio") || "Select Ratio"}
              ratios={getRatios().toList().filter(r => !r.get("isDeleted"))}
              onRatioSelect={(r) => {
                onConfigChange(
                    config
                        .set("title", r.get("name"))
                        .set("id", r.get("id"))
                        .set("selectedRatio", r.get("name"))
                        .set("firstKpiId", r.get("firstKpiId"))
                        .set("secondKpiId", r.get("secondKpiId"))
                        .set("displayType", r.get("displayType"))
                        .set("type", "global")
                );
              }}
              closeOnSelect={true} />
        </div>
      </div>
    </div>
    <div style={{marginBottom: "0.3rem", display: "flex", alignItems: "flex-start"}}>
      <span data-test-id="new-ratio-button">
        <Radio
            checked={newRatioSelected}
            onChange={() => {
              onConfigChange(config.delete("selectedRatio").set("type", "custom"));
              setNewRatioSelected(!newRatioSelected);
            }} />
      </span>
      <div style={{paddingTop: "0.7rem"}}>
        <h3 style={{fontSize: 14}}>Configure a custom ratio</h3>
        <div style={{opacity: newRatioSelected ? 1 : 0}}>
          <CustomRatioMaker kpis={kpis} onConfigChange={onConfigChange} config={config} />
        </div>
      </div>
    </div>
  </div>;
});

const renderDialog = (config, data, onParentConfigChange, onRequestDialog) => {

  const kpiDetailsOptions = {
    timeframe: TimeframeRepo.parse(config.get("timeframe").toJS()),
    clientIds: config.getIn(["clientFilter", "allClientIds"], Immutable.List()),
    entityToIds: config.get("entityToIds", Immutable.Map()).toObject(),
    ...DashboardUtils.configToQualifierAjaxParams(config)
  };

  const ratioDetailsOptions = {
    initialTab: "DETAILS",
    ratio: Immutable.Map({
      name: config.get("name"),
      firstKpiId: config.get("firstKpiId"),
      secondKpiId: config.get("secondKpiId"),
      displayType: config.get("displayType")
    }),
    ratioData: Immutable.Map({
      firstKpiData: Immutable.Map(Immutable.fromJS(data.get("value")[0])),
      secondKpiData: Immutable.Map(Immutable.fromJS(data.get("value")[1]))
    }),
    timeframe: TimeframeRepo.parse(config.get("timeframe").toJS()),
    clientIds: config.getIn(["clientFilter", "allClientIds"], Immutable.List()),
    ...DashboardUtils.configToQualifierAjaxParams(config)
  };

  const title = (
      <div style={{display: "inline-block"}}>
      <span
          style={{
            fontSize: 20,
            textTransform: "none",
            fontWeight: 500
          }}>
          {config.get("name")}
      </span>
        <span style={{fontWeight: 500}}>
        {` (${TimeframeRepo.parse(config.get("timeframe").toJS()).attributes.name})`}
      </span>
        {renderUserOrGroupDetails(config)}
      </div>
  );

  const kpiDialog = (kpiId) => {
    const closeDialog = onRequestDialog(
        <KpiDetailsDialog
            renderContentOnly
            kpiId={kpiId}
            {...kpiDetailsOptions}
            matchAnyTagIds={config.getIn(["tagFilter", "matchAnyTagIds"], Immutable.List())}
            matchAllTagIds={config.getIn(["tagFilter", "matchAllTagIds"], Immutable.List())}
            excludedTagIds={config.getIn(["tagFilter", "excludedTagIds"], Immutable.List())}
            onGroupClick={groupId => {
              onParentConfigChange(parentConfig => parentConfig.set("qualifier", Immutable.Map({
                type: "GROUP",
                id: groupId
              })), true);
              closeDialog();
            }}
            onUserClick={userId => {
              onParentConfigChange(parentConfig => parentConfig.set("qualifier", Immutable.Map({
                type: "USER",
                id: userId
              })), true);
              closeDialog();
            }} />,
        {title: title});
  };

  const closeDialog = onRequestDialog(
      <RatioDetailsDialog
          renderContentOnly
          {...ratioDetailsOptions}
          matchAnyTagIds={config.getIn(["tagFilter", "matchAnyTagIds"], Immutable.List())}
          matchAllTagIds={config.getIn(["tagFilter", "matchAllTagIds"], Immutable.List())}
          excludedTagIds={config.getIn(["tagFilter", "excludedTagIds"], Immutable.List())}
          onKpiClick={kpiId => {
            closeDialog();
            kpiDialog(kpiId);
          }}
      />,
      {title: title, minWidth: "fit-content", minHeight: 260});
};

export default Immutable.fromJS({
  type: "Ratio",
  label: "Ratio",
  titleIsEditable: true,
  getTitle: component => {
    const config = component.get("dataConfig");
    let firstKpiId;
    let secondKpiId;
    let name;
    const ratioId = config.get("id");
    const ratio = RatioRepo.get(ratioId);

    if (config.get("type") === "global") {
      firstKpiId = ratio.get("firstKpiId");
      secondKpiId = ratio.get("secondKpiId");
      name = ratio.get("name");
    } else {
      firstKpiId = config.get("firstKpiId");
      secondKpiId = config.get("secondKpiId");
    }
    if (firstKpiId && secondKpiId) {
      const firstKpiName = KpiRepo.get(firstKpiId).get("name");
      const secondKpiName = KpiRepo.get(secondKpiId).get("name");
      if (component.get("title") || component.get("title") === "") {
        return component.get("title");
      } else if (name) {
        return name;
      } else {
        return `${firstKpiName} / ${secondKpiName}`;
      }
    } else {
      return "New Ratio";
    }
  },
  getDefaultData: () => Immutable.Map(),
  getReactComponent: () => Ratio,
  getEditorReactComponent: () => RatioEditor,
  canFullScreen: false,
  variant: "standard",
  layout: {
    min: {width: 6, height: 4},
    max: {width: 16, height: 16},
    init: {width: 6, height: 4}
  },
  applyParentConfig: (parentConfig, dataConfig) => DashboardUtils.applyInheritanceForKeys(parentConfig, dataConfig),
  load: component => {
    const config = component.get("dataConfig");

    let firstKpiId;
    let secondKpiId;

    if (config.get("type") === "global") {
      const ratioId = config.get("id");
      const ratio = RatioRepo.get(ratioId);

      firstKpiId = ratio.get("firstKpiId");
      secondKpiId = ratio.get("secondKpiId");
    } else {
      firstKpiId = config.get("firstKpiId");
      secondKpiId = config.get("secondKpiId");
    }

    if (firstKpiId && secondKpiId) {
      // if filters are set don't update the ratios
      const ajaxParams = {
        dateFromUI: moment().format("YYYY-MM-DD"),
        timeframe: TimeframeRepo.parse(config.get("timeframe").toJS()),
        clientIds: config.getIn(["clientFilter", "allClientIds"]).toArray(),
        matchAnyTagIds: config.getIn(["tagFilter", "matchAnyTagIds"]).toArray(),
        matchAllTagIds: config.getIn(["tagFilter", "matchAllTagIds"]).toArray(),
        excludedTagIds: config.getIn(["tagFilter", "excludedTagIds"]).toArray(),
        entityToIds: config.get("entityToIds", Immutable.Map()).toObject(),
        ...DashboardUtils.configToQualifierAjaxParams(config),
        alwaysAllowClientFilter: true
      };

      const firstPromise = KpiCalculator
          .summary(firstKpiId, ajaxParams)
          .then(response => response);

      const secondPromise = KpiCalculator
          .summary(secondKpiId, ajaxParams)
          .then(response => response);

      return Promise
          .all([firstPromise, secondPromise])
          .then(results => {
            return Promise.resolve(results);
          });

    } else {
      return Promise.resolve(Immutable.Map());
    }
  }
});
