import numbro from "numbro";
import Immutable from "immutable";

import * as currencyRepo from "js/common/repo/backbone/currency-repo";
import * as numbers from "js/common/utils/numbers";
import {roundTo} from "js/common/utils/numbers";
import {firstWithValue} from "js/common/utils/value-checking";

const numbroOptions = {thousandSeparated: true};

const getType = (valueFormat, currencyCode, currencySymbol) => {
  if (valueFormat) {
    return valueFormat;
  } else if (currencyCode || currencySymbol) {
    return "CURRENCY";
  } else {
    return "NUMBER";
  }
};

const format = (
    wrapper,
    {
      valueFormat,
      maxDisplayLength,
      decimalPlaces,
      showCurrency = true,
      currencySymbol,
      currencyCode,
      abbreviationOptions,
      returnSeparate = false // Option for returning individual components
    } = {}
) => {
  if (!wrapper) {
    return "N/A";
  }

  if (Immutable.isImmutable(wrapper)) {
    wrapper = wrapper.toJS();
  }

  const {value, currency, decimalPlaces: valueDecimalPlaces} = wrapper;
  const currencyCodeToUse = currency || currencyCode;
  // TODO PRECISION should this just check if value is a not a number?
  if (value === "N/A") {
    return value;
  }

  const decimalPlacesToUse = firstWithValue(decimalPlaces, valueDecimalPlaces, 0);
  const type = getType(valueFormat, currencyCodeToUse, currencySymbol);
  const number = value || 0;

  const formatNumber = (numberValue) => numbro(Math.abs(numberValue)).format(numbroOptions);
  const applySign = (sign, currencySymbolToUse, formattedNumber) =>
      sign + (currencySymbolToUse ? currencySymbolToUse : "") + formattedNumber;

  switch (type) {
    case "PERCENT":
      return numbers.toPercentageStr(number, decimalPlacesToUse);

    case "CURRENCY": {
      if (showCurrency && (currencyCodeToUse || currencySymbol)) {
        const currencySymbolToUse = currencySymbol || currencyRepo.getSymbol(currencyCodeToUse);
        const roundedNumber = roundTo(number, decimalPlacesToUse);
        const sign = roundedNumber < 0 ? "-" : "";
        const formattedAbsoluteNumber = formatNumber(roundedNumber);

        if (maxDisplayLength && (sign + currencySymbolToUse + formattedAbsoluteNumber).length > maxDisplayLength) {
          const formattedBigNumber = numbers.formatBigNumber(
              Math.abs(roundedNumber),
              {fallbackValue: formattedAbsoluteNumber, ...abbreviationOptions}
          );

          if (returnSeparate) {
            return {currencySymbol: sign + currencySymbolToUse, numericValue: formattedBigNumber};
          }
          return applySign(sign, currencySymbolToUse, formattedBigNumber);
        }

        if (returnSeparate) {
          return {currencySymbol: sign + currencySymbolToUse, numericValue: formattedAbsoluteNumber};
        }
        return applySign(sign, currencySymbolToUse, formattedAbsoluteNumber);
      }

      const options = {valueFormat: "NUMBER", maxDisplayLength, abbreviationOptions};
      return format({value: number, decimalPlaces: decimalPlacesToUse}, options);
    }

    case "NUMBER": {
      const roundedValue = numbers.roundTo(number, decimalPlacesToUse).toString();

      if (maxDisplayLength && roundedValue.length > maxDisplayLength) {
        const bigNumberFormatted = numbers.formatBigNumber(number, {
          fallbackValue: roundedValue,
          ...abbreviationOptions
        });

        if (returnSeparate) {
          return {currencySymbol: "", numericValue: bigNumberFormatted};
        }
        return bigNumberFormatted;
      }

      if (returnSeparate) {
        return {currencySymbol: "", numericValue: roundedValue};
      }
      return roundedValue;
    }

    default:
      return "";
  }
};

export {format};
