import React from "react";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";

import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as Formatter from "js/common/utils/formatter";

import currentClient from "js/common/repo/backbone/current-client";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const GroupOverviewPanel = createReactClass({

  mixins: [PureRenderMixin],

  render() {
    const {config, data} = this.props;
    const client = currentClient;
    const clientName = client.getClientName();
    const imgUrl = client.getLogoUrl();
    const hasKpis = !config.get("kpis").isEmpty();
    const logoImgContainerStyle = {
      height: "33.33%",
      width: "auto",
      marginTop: hasKpis ? 0 : "2vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    };
    const logoImgStyle = {
      maxHeight: "90%",
      width: "auto",
      position: "relative",
      objectFit: "contain",
      filter: "drop-shadow(0 0 24px rgba(30,30,25,0.67))"
    };
    const title = config.get("title");
    return (
        <div style={{height: "100%"}}>
          <div
              style={{
                height: "6vh",
                backgroundColor: title ? "#f9ec33" : "transparent",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around"
              }}>
            <div style={headerStyle(this.props.theme)}>
              {title}
            </div>
          </div>
          <div style={{...panelStyle, height: "calc(100% - 7.5vh)"}}>
            <div style={logoImgContainerStyle}>
              <img style={logoImgStyle} src={`${imgUrl}`} alt={clientName} />
            </div>
            {hasKpis && data && this.renderKpisDataSummary()}
          </div>
        </div>
    );
  },

  renderKpisDataSummary() {
    const maxKpiValueCharLength = 10;
    return this.props.data
        .sortBy(data => data.get("order"))
        .map((kpiData, index) => {
          const kpiId = kpiData.get("kpiId");
          const kpi = kpiRepo.get(kpiId);
          const kpiValueFormat = kpi.get("valueFormat");
          const kpiTotal = kpiData.get("total");
          const kpiValueLabel = kpiTotal.get("type") === "error" ?
              kpiTotal.get("value") :
              Formatter.format(kpiTotal.toJS(), {kpiValueFormat, maxDisplayLength: maxKpiValueCharLength});
          const kpiNameDisplayStyle = {
            fontFamily: this.props.theme.typography.fontFamilyBold,
            fontSize: "1.85vw",
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            color: "#fff"
          };
          return (
              <div key={`${kpiId}-${index}`} style={summaryContainerStyle}>
                <div style={kpiNameDisplayStyle}>
                  {kpiData.get("displayName")}
                </div>
                <div style={kpiValueLabelStyle(this.props.theme)}>{kpiValueLabel}</div>
              </div>
          );
        });
  }

});

const textStyle = theme => ({
  fontFamily: theme.typography.fontFamilyBold,
  fontSize: "2vw",
  color: "#fff",
  textShadow: "2px 2px 8px #000"
});

const headerStyle = theme => ({
  ...textStyle(theme),
  fontSize: "1.5vw",
  color: "#000",
  textShadow: "none",
  textTransform: "uppercase",
  paddingLeft: "1vh",
  paddingRight: "1vh",
  overflow: "hidden",
  textOverflow: "ellipsis"
});

const panelStyle = {
  overflow: "hidden",
  border: "3px solid #B9B9B9",
  backgroundColor: "rgba(44, 53, 90, 0.56)",
  marginTop: "1vh",
  textAlign: "center",
  ...textStyle
};

const summaryContainerStyle = {
  height: "33.33%",
  borderTop: "3px solid #587BBE",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center"
};

const kpiValueLabelStyle = theme => ({
  fontFamily: theme.typography.fontFamilyBold,
  color: "#f9ec33",
  fontSize: "3.8vw",
  lineHeight: 1
});

const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <GroupOverviewPanel theme={theme} {...props} />;
};

export default Wrapper;
