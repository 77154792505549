/** @jsxImportSource @emotion/react */

import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import ReactDOM from "react-dom";
import ImmutablePropTypes from "react-immutable-proptypes";
import PureRenderMixin from "react-addons-pure-render-mixin";

import ArrowIcon from "js/oneview/targets/arrow-icon";
import SimpleTargetView from "js/oneview/targets/simple-target-view";
import EditableTargetView from "js/oneview/targets/editable-target-view";
import TargetNotes from "js/oneview/targets/target-notes";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const $ = window.$;

const TargetList = createReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
    viewType: ReactPropTypes.string.isRequired,
    timeframe: ReactPropTypes.object.isRequired,
    targets: ImmutablePropTypes.list.isRequired,
    onTargetClick: ReactPropTypes.func.isRequired,
    onCloseNotesRequest: ReactPropTypes.func.isRequired,
    onAddTargetNoteRequest: ReactPropTypes.func,
    selectedTargetId: ReactPropTypes.number,
    onTargetChange: ReactPropTypes.func,
    errorsByTargetId: ImmutablePropTypes.map,
    isUpdatingNotes: ReactPropTypes.bool,
    addNoteError: ReactPropTypes.string
  },

  getInitialState() {
    return {
      isNotesOpen: false,
      openDatePickerRef: undefined
    };
  },

  componentDidMount() {
    if (this.props.targets.isEmpty()) {
      return;
    }

    const $this = $(ReactDOM.findDOMNode(this));
    const $targetsList = $this.find(".targets-list");
    $targetsList.scrollTop(".is-in-timeframe:first");
  },

  render() {
    const {
      viewType,
      targets,
      onTargetChange,
      selectedTargetId,
      errorsByTargetId
    } = this.props;
    const TargetView = targetViewByType[viewType];
    const isHeaderVisible = !targets.isEmpty();
    const isNotesPanelOpen = !!selectedTargetId;
    const isNotesVisible = targets.some(t => t.get("notesVisible"));
    const trStyle = {
      width: "100%",
      display: "flex",
      flexFlow: "row nowrap"
    };
    const thStyle = {
      display: "none",
      fontWeight: 700,
      color: "#9e9e9e",
      fontSize: "0.9rem"
    };
    const tdStyle = {
      display: "flex",
      flexFlow: "row nowrap",
      flexGrow: 1,
      flexBasis: 0,
      paddingLeft: "0.5em",
      paddingRight: "0.5em",
      wordBreak: "break-word",
      overflow: "hidden",
      textOverflow: "ellipsis",
      minWidth: 0,
      whiteSpace: "nowrap"
    };
    const bigScreenNotesStyle = {
      display: "none",
      flex: "1 1 25%",
      marginLeft: "1rem",
      [`@media(min-width: 900px)`]: {display: "block"}
    };
    const smallScreenNotesStyle = {
      display: "none",
      [`@media(max-width: 899px)`]: {display: "block"}
    };
    return (
        <div style={{display: "flex", overflowY: "visible", margin: "1rem", flex: "1 1 auto"}}>
          <div style={{display: "flex", flexFlow: "column nowrap", flex: "1 1 auto"}}>
            {isHeaderVisible &&
                <div style={{...thStyle, ...trStyle, padding: "0.5rem 1.5rem 0.5rem 0.5rem"}}>
                  <span css={{...tdStyle, flexGrow: 0.2}}>Priority</span>
                  <span css={tdStyle}>Value</span>
                  <div css={tdStyle}>
                    Start <ArrowIcon
                      style={{
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                        paddingTop: "0.15rem"
                      }} /> End
                  </div>
                  {isNotesVisible && <span css={{...tdStyle, flex: isNotesPanelOpen ? "0.2" : 1}}>Notes</span>}
                </div>}

            <div className="targets-list" style={{overflowY: "visible", flex: "1 1 auto"}}>
              {targets.isEmpty() &&
                  <div style={{textAlign: "center", margin: "1rem"}}>
                    No targets have been set
                  </div>}

              {targets.map(target => {
                const targetId = target.get("id");
                const isSelected = selectedTargetId && (targetId === selectedTargetId);

                return (
                    <div key={targetId}>
                      <TargetView
                          setOpenDatePicker={this.setOpenDatePicker}
                          target={target}
                          theme={this.props.theme}
                          isTargetInTimeframe={isTargetInTimeframe(target, this.props.timeframe)}
                          highlightCurrentTarget={!selectedTargetId}
                          isNotesPanelOpen={isNotesVisible && isNotesPanelOpen}
                          isSelected={isSelected}
                          onClick={this.handleTargetClick}
                          onChange={onTargetChange}
                          error={errorsByTargetId.get(targetId)} />
                      {(isNotesVisible && isSelected) &&
                          <div css={smallScreenNotesStyle}>
                            {this.renderTargetNotes()}
                          </div>}
                    </div>);
              })}
            </div>
          </div>

          {(isNotesVisible && selectedTargetId) &&
              <div css={bigScreenNotesStyle}>
                {this.renderTargetNotes()}
              </div>}
        </div>);
  },


  setOpenDatePicker(ref) {
    const oldRef = this.state.openDatePickerRef;
    if (oldRef && ref !== oldRef) {
      oldRef && oldRef.hide();
    }
    this.setState({openDatePickerRef: ref});
    // Make sure we can always see the newly opened picker
    ref.datePickerInputRef.scrollIntoView();
  },


  renderTargetNotes() {
    const {
      viewType,
      targets,
      selectedTargetId,
      onCloseNotesRequest,
      onAddTargetNoteRequest,
      isUpdatingNotes,
      addTargetNoteError
    } = this.props;
    const index = targets.findIndex(t => t.get("id") === selectedTargetId);
    return (
        <TargetNotes
            viewType={viewType}
            targetId={selectedTargetId}
            notes={targets.get(index).get("noteAssignmentDtos")}
            onCloseRequest={onCloseNotesRequest}
            onAddNoteClick={onAddTargetNoteRequest}
            isUpdating={isUpdatingNotes}
            error={addTargetNoteError} />
    );
  },

  handleTargetClick(targetId) {
    const {targets, onTargetClick} = this.props;
    const isNotesVisible = targets.some(t => t.get("notesVisible"));
    if (isNotesVisible) {
      onTargetClick(targetId);
    }
  }

});

const targetViewByType = {
  SIMPLE: SimpleTargetView,
  EDIT: EditableTargetView
};

const isTargetInTimeframe = (target, timeframe) => {
  const timeframeStartDate = timeframe.get("start");
  const timeframeEndDate = timeframe.get("end");

  const targetStartDate = target.get("targetStart");
  const targetEndDate = target.get("targetEnd");

  return targetStartDate.isBetween(timeframeStartDate, timeframeEndDate, null, "[]")
      || targetEndDate.isBetween(timeframeStartDate, timeframeEndDate, null, "[]")
      || timeframeStartDate.isBetween(targetStartDate, targetEndDate, null, "[]")
      || timeframeEndDate.isBetween(targetStartDate, targetEndDate, null, "[]");
};


export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <TargetList theme={theme} {...props} />;
};
