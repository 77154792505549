import {createTheme, alpha, adaptV4Theme} from "@mui/material";
import baseThemeStyles from "js/common/themes/base-theme";
import * as colors from "js/common/cube19-colors";

const theme = () => createTheme(adaptV4Theme({
  themeId: "dark",
  typography: {
    fontFamily: "Oxygen-regular, Arial, sans-serif",
    fontFamilyBold: "Oxygen-bold, Arial, sans-serif",
  },
  props: {
    MuiButtonBase: {
      disableRipple: true // No more ripple, on the whole application 💣!
    },
    MuiInputBase: {
      variant: "standard",
    }
  },
  palette: {
    type: "dark",
    primary: {
      main: colors.c19Yellow
    },
    error: {
      main: colors.pomegranate,
      background: "#f8d7d7",
    },
    warning: {
      main: colors.c19Yellow
    },
    danger: {
      color: colors.white,
      background: "#750000",
      border: "#ff8d00"
    },
    success: {
      main: colors.malachite,
      background: "#D6F8CD"
    },
    text: {
      primary: colors.white,
      default: colors.white,
      inverted: "black",
      main: colors.white,
      lighter: "#f3f3f3",
      disabled: colors.boulder,
      prefix: "#999999"
    },
    background: {
      default: colors.tuna,
      paper: colors.tuna,
      card: colors.charade,
      light: colors.trout,
      inverted: colors.white,
      invertedChip: colors.wildSand,
      warning: "#FFE3B9",
    },
    border: {
      main: colors.tuna,
      dark: colors.charade,
      light: colors.trout,
      lightest: "rgba(0,0,0,0.2)",
      warning: "#FF981F",
    },
    hints: {
      text: "#333",
      background: colors.ocean,
      border: colors.bullhornLightBlue
    },
    // theme notes: review default and main, surely we should only use main
    drawer: {
      default: colors.tuna,
      dark: colors.charade,
      controls: "#202028"
    },
    button: {
      primary: colors.c19Yellow,
      success: "#136300",
      alert: "#DA4453",
      dark: colors.charade,
      bright: colors.tuna,

      hover: {
        primary: alpha("#f9ec33", 0.3),
        success: alpha("#136300", 0.3),
        alert: alpha("#6f1313", 0.7),
        dark: colors.tuna,
        bright: colors.charade
      }
    },
    buttonIcon: {
      bright: "#fff",
      dark: "#717171",
      success: "#2ad600",
      alert: "#f00",

      hover: {
        bright: "#eaeaea",
        dark: "#555555",
        success: "#136300",
        alert: "#ce4d4d"
      }
    },
    oneView: {
      main: colors.c19Yellow,
      curveColor: colors.boulder,
      userIcon: colors.silverChalice
    },
    squids: {
      main: colors.java
    },
    jobsPipeline: {
      main: colors.turquoise
    },
    sliceAndDice: {
      main: colors.portage
    },
    onPoint: {
      main: colors.crusta
    },
    trends: {
      main: colors.malibu
    },
    cubeTv: {
      main: colors.cinnabar
    },
    dashboards: {
      ahead: "#8CC152",
      ok: "#F6B042",
      behind: "#DA4453"
    },
    app: {
      matchAnyColor: colors.tidal,
      matchAllColor: colors.anakiwa,
      excludeColor: colors.romantic
    },
    black: colors.black,
    white: colors.white,
    textColor: colors.white,
    alternateTextColor: colors.charade,
    canvasColor: colors.tuna,
    borderColor: colors.trout,
    disabledColor: colors.boulder
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        h1: {
          fontWeight: 700,
        },
        h2: {
          fontWeight: 700
        },
        h3: {
          fontWeight: 700
        }
      },
    },
    MuiCard: {
      root: {
        backgroundColor: colors.charade,
        backgroundImage: "none"
      }
    },
    MuiPaper: {
      root: {
        color: colors.white,
        backgroundImage: "none"
      }
    },
    // MuiInput: {
    //   root: {
    //     fontSize: 14,
    //   }
    // },
    MuiButton: {
      variants: "bright",
      text: {
        background: colors.charade,
        borderRadius: 3,
        border: 0,
        color: colors.white,
        height: 48,
        padding: "0 30px",
        boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .1)"
      }
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 14,
      }
    }
  },
}, baseThemeStyles));

export default theme;
