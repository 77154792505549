import {createTheme, alpha, adaptV4Theme} from "@mui/material";
import baseThemeStyles from "js/common/themes/base-theme";
import * as colors from "js/common/cube19-colors";

const theme = () => createTheme(adaptV4Theme({
  themeId: "light",
  typography: {
    fontFamily: "Montserrat, Verdana, sans-serif",
    fontFamilyBold: "Montserrat, Verdana, sans-serif",
  },
  props: {
    MuiButtonBase: {
      disableRipple: true // No more ripple, on the whole application 💣!
    }
  },
  overrides: {
    MuiCard: {
      root: {
        backgroundColor: "#efefef",
        backgroundImage: "none"
      }
    },
    MuiPaper: {
      root: {
        color: colors.black,
        backgroundImage: "none"
      }
    },
    MuiButton: {
      text: {
        background: "#efefef",
        borderRadius: 3,
        border: 0,
        color: "black",
        height: 48,
        padding: "0 30px"
      }
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 13,
      }
    }
  },
  palette: {
    type: "light",
    primary: {
      main: colors.bullhornLightBlue
    },
    error: {
      main: colors.pomegranate,
      background: "#f8d7d7",
    },
    warning: {
      main: "#F6B042"
    },
    danger: {
      color: colors.black,
      background: "#f1d5d8",
      border: "#da4453"
    },
    success: {
      main: colors.malachite,
      background: "#D6F8CD"
    },
    text: {
      primary: colors.bullhornNavy,
      inverted: "white",
      main: "#282828",
      lighter: "#8A8886",
      disabled: colors.boulder,
      prefix: "#999999"
    },
    background: {
      default: colors.wildSand,
      paper: colors.wildSand,
      card: colors.white,
      light: colors.wildSand,
      inverted: colors.charade,
      invertedChip: colors.tuna,
      warning: "#FFE3B9",
    },
    border: {
      main: colors.wildSand,
      dark: colors.charade,
      light: "#ccc",
      lightest: "#EDEBE9",
      warning: "#FF981F",
    },
    hints: {
      text: "#333",
      background: colors.ocean,
      border: colors.bullhornLightBlue
    },
    drawer: {
      default: colors.tuna,
      dark: colors.charade,
      controls: "#202028"
    },
    button: {
      primary: colors.bullhornLightBlue,
      success: "#136300",
      alert: "#DA4453",
      dark: colors.charade,
      bright: colors.tuna,

      hover: {
        primary: alpha("#f9ec33", 0.3),
        success: alpha("#136300", 0.3),
        alert: alpha("#6f1313", 0.7),
        dark: colors.tuna,
        bright: colors.charade
      }
    },
    buttonIcon: {
      bright: "#fff",
      dark: "#717171",
      success: "#2ad600",
      alert: "#f00",

      hover: {
        bright: "#eaeaea",
        dark: "#555555",
        success: "#136300",
        alert: "#ce4d4d"
      }
    },
    oneView: {
      main: colors.bullhornLightBlue,
      curveColor: colors.boulder,
      userIcon: colors.silverChalice
    },
    squids: {
      main: colors.bullhornLightBlue
    },
    jobsPipeline: {
      main: colors.bullhornLightBlue
    },
    sliceAndDice: {
      main: colors.portage
    },
    onPoint: {
      main: colors.bullhornLightBlue
    },
    trends: {
      main: colors.bullhornLightBlue
    },
    cubeTv: {
      main: colors.bullhornLightBlue
    },
    dashboards: {
      ahead: "#107C10",
      ok: "#F6B042",
      behind: "#DA4453"
    },
    app: {
      matchAnyColor: colors.tidal,
      matchAllColor: colors.anakiwa,
      excludeColor: colors.romantic
    },
    black: colors.black,
    white: colors.white,
    textColor: colors.black,
    alternateTextColor: colors.charade,
    canvasColor: colors.tuna,
    borderColor: colors.trout,
    disabledColor: colors.boulder
  }
}, baseThemeStyles));

export default theme;
