import React from "react";
import moment from "moment";
import Immutable from "immutable";

import * as KpiRepo from "js/common/repo/backbone/kpi-repo";
import * as KpiCalculator from "js/common/kpi-calculator";
import * as DashboardUtils from "js/dashboards/utils";
import * as TimeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";

import PriorityMetric from "js/common/views/priority-metric";
import KpiPicker from "js/common/views/kpis/kpi-picker";
import DataConfigToolbar from "js/dashboards/data-config-toolbar";
import * as Overlays from "js/dashboards/components/overlays";
import NoTargetPriorityMetric from "js/common/views/no-target-priority-metric";
import KpiDetailsDialog from "js/oneview/kpi-details/dialog";
import {renderUserOrGroupDetails} from "js/dashboards/utils";

const MetricCurve = React.memo(({
  dimensions,
  data,
  config,
  onRequestDialog,
  onParentConfigChange
}) => {
  const kpi = KpiRepo.get(config.get("kpiId"));
  const notFilterableByClient = kpi && (!config.getIn(["clientFilter", "allClientIds"]).isEmpty() && !kpi.get("filterableByClient"));
  const hasTarget = data.getIn(["value", "target", "value"], 0) > 0;

  if (!kpi) {
    return <Overlays.Message coverOtherContent={false}>
      Choose a Metric
    </Overlays.Message>;
  } else if (hasTarget && !notFilterableByClient) {
    return <PriorityMetric
        kpiId={config.get("kpiId")}
        dimensions={dimensions}
        kpiData={data
            .get("value")
            .update("total", x => x.toJS())
            .update("expectedValue", x => x.toJS())
            .update("target", x => x.toJS())}
        onClick={() => renderDialog(config, onParentConfigChange, onRequestDialog)}
        clientIds={config.getIn(["clientFilter", "allClientIds"], Immutable.Set())}
        hasAppliedTagFilters={hasAppliedTagFilters(config)} />;
  } else {
    return <NoTargetPriorityMetric
        dimensions={dimensions}
        config={config}
        data={data}
        onClick={() => renderDialog(config, onParentConfigChange, onRequestDialog)}
        clientIds={config.getIn(["clientFilter", "allClientIds"], Immutable.Set())}
    />;
  }
});

const MetricCurveEditor = React.memo(({
  component,
  onComponentChange
}) => {
  const config = component.get("dataConfig");

  const onConfigChange = React.useCallback(newDataConfig => {
    onComponentChange(component.set("dataConfig", newDataConfig));
  }, [onComponentChange, component]);

  const kpis = React.useMemo(
      () => Immutable.fromJS(KpiRepo
          .getAll()
          .toJSON())
          .filter(k => k.get("visible") || k.get("id") === config.get("kpiId")),
      [config]);

  return <div style={{padding: "0.5rem"}}>
    <div style={{width: "50%", marginBottom: "0.3rem"}}>
      <KpiPicker
          placeholder="Choose Metric"
          multiSelect={false}
          clearable={false}
          kpis={kpis}
          selectedKpiId={config.get("kpiId")}
          onChange={kpiId => {
              const kpi = kpiRepo.get(kpiId);
              onConfigChange(config.set("kpiId", kpiId).set("title", kpi.get("name")))
          }} />
    </div>
    <DataConfigToolbar config={config} onConfigChange={onConfigChange} />
  </div>;
});

const renderDialog = (config, onParentConfigChange, onRequestDialog) => {
  const kpi = kpiRepo.get(config.get("kpiId"));
  // TODO JC - why are the tag id params not set here?
  // TODO JC - is it expected for client / tag id params to be immutable when passed to the dialog?
  const kpiDetailsOptions = {
    kpiId: config.get("kpiId"),
    timeframe: TimeframeRepo.parse(config.get("timeframe").toJS()),
    clientIds: config.getIn(["clientFilter", "allClientIds"], Immutable.List()),
    entityToIds: config.get("entityToIds", Immutable.Map()).toObject(),
    ...DashboardUtils.configToQualifierAjaxParams(config)
  };
  const title = (
      <div style={{display: "inline-block"}}>
      <span style={{
        fontSize: 20,
        textTransform: "none",
        fontWeight: 500
      }}>
          {kpi.get("name")}
      </span>
        <span style={{fontWeight: 500}}>
        {` (${TimeframeRepo.parse(config.get("timeframe").toJS()).attributes.name})`}
      </span>
        {renderUserOrGroupDetails(config)}
      </div>
  );
  const closeDialog = onRequestDialog(
      <KpiDetailsDialog
          renderContentOnly
          {...kpiDetailsOptions}
          matchAnyTagIds={config.getIn(["tagFilter", "matchAnyTagIds"], Immutable.List())}
          matchAllTagIds={config.getIn(["tagFilter", "matchAllTagIds"], Immutable.List())}
          excludedTagIds={config.getIn(["tagFilter", "excludedTagIds"], Immutable.List())}
          onGroupClick={groupId => {
            onParentConfigChange(parentConfig => parentConfig.set("qualifier", Immutable.Map({
              type: "GROUP",
              id: groupId
            })), true);
            closeDialog();
          }}
          onUserClick={userId => {
            onParentConfigChange(parentConfig => parentConfig.set("qualifier", Immutable.Map({
              type: "USER",
              id: userId
            })), true);
            closeDialog();
          }} />,
      {title: title});
};

const hasAppliedTagFilters = (config) => {
  return !config.getIn(["tagFilter", "matchAnyTagIds"]).isEmpty() ||
      !config.getIn(["tagFilter", "matchAllTagIds"]).isEmpty() ||
      !config.getIn(["tagFilter", "excludedTagIds"]).isEmpty();
};

export default Immutable.fromJS({
  type: "MetricCurve",
  label: "Metric",
  titleIsEditable: true,
  getTitle: component => {
    if(component) {
      const config = component.get("dataConfig");
      if(config) {
        const kpiId = config.get("kpiId");
        const kpi = kpiRepo.get(kpiId);
        if(kpi) {
          return component.get("title") || component.get("title") === "" ? component.get("title") : kpi.get("name")
        } else {
          return "New Metric"
        }
      } else {
        return "New Metric"
      }
    } else {
      return "New Metric"
    }
  },
  getDefaultData: () => Immutable.Map(),
  getReactComponent: () => MetricCurve,
  getEditorReactComponent: () => MetricCurveEditor,
  canFullScreen: false,
  variant:"standard",
  layout: {
    min: {width: 6, height: 4},
    max: {width: 16, height: 16},
    init: {width: 6, height: 4}
  },
  applyParentConfig: (parentConfig, dataConfig) => DashboardUtils.applyInheritanceForKeys(parentConfig, dataConfig),
  load: component => {
    const config = component.get("dataConfig");
    const kpiId = config.get("kpiId");
    const kpi = kpiRepo.get(kpiId);
    if (config.has("kpiId")) {
      if(!config.getIn(["clientFilter", "allClientIds"]).isEmpty() && !kpi.get("filterableByClient")) {
        return Promise.resolve(Immutable.Map())
      } else {
        const ajaxParams = {
          dateFromUI: moment().format("YYYY-MM-DD"),
          timeframe: TimeframeRepo.parse(config.get("timeframe").toJS()),
          clientIds: config.getIn(["clientFilter", "allClientIds"]).toArray(),
          matchAnyTagIds: config.getIn(["tagFilter", "matchAnyTagIds"]).toArray(),
          matchAllTagIds: config.getIn(["tagFilter", "matchAllTagIds"]).toArray(),
          excludedTagIds: config.getIn(["tagFilter", "excludedTagIds"]).toArray(),
          entityToIds: config.get("entityToIds", Immutable.Map()).toObject(),
          ...DashboardUtils.configToQualifierAjaxParams(config)
        };
        return KpiCalculator
            .summary(config.get("kpiId"), ajaxParams)
            .then(response => Immutable.fromJS(response));
      }
    } else {
      return Promise.resolve(Immutable.Map());
    }
  }
});
