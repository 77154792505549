import React from "react";
import Immutable from "immutable";
import {clientPermissions} from "js/common/permissions";

import {betterMemo} from "js/common/utils/more-memo";
import {bullhornSupportTicketDocsUrl} from "js/common/branding-constants";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import TabSection from "js/admin/kpis/edit-kpis/tabs/common/tab-section";
import ConfigOwnership from "js/admin/kpis/edit-kpis/tabs/config-ownership";
import EntityColumnPicker from "js/admin/kpis/edit-kpis/tabs/common/entity-column-picker";
import {getQueryParam, useConfigFieldChange, isRestrictedQueryType, isKpiEditable} from "js/admin/kpis/edit-kpis/utils";
import ConfigInheritFrom from "js/admin/kpis/edit-kpis/tabs/config-inherits-from";
import Filters from "js/admin/kpis/edit-kpis/config-filters";
import InheritSwitch from "js/common/inherit-switch";
import Hint from "js/admin/common/hint";
import Icon from "js/admin/common/icon";
import currentClient from "js/common/repo/backbone/current-client";
import TooltipLabel from "js/admin/kpis/edit-kpis/tabs/common/tooltip-label";
import * as Branding from "js/common/branding-constants";
import Checkbox from "js/common/views/inputs/checkbox";
import TimeframePicker from "js/common/views/inputs/timeframe-picker/react-timeframe-picker";
import * as timeframeRepo from "js/common/repo/backbone/timeframe-repo";

const fieldGridStyle = {
  display: "flex",
  flexDirection: "column",
  width: "49%",
  marginBottom: "1rem"
};

const filterSectionHeadingStyle = {
  fontWeight: 700,
  textTransform: "uppercase",
  fontSize: 14,
  marginBottom: 5
};

const inheritedFiltersSectionTitle = "Inherited Metric Filters";
const filtersSectionTitle = "Metric Specific Filters";

const renderFilterSectionTitle = (label, labelTooltip) => {
  return <div style={{display: "flex", alignContent: "center"}}>
    {labelTooltip && <TooltipLabel
        label={label}
        tooltip={labelTooltip}
        customLabelStyle={filterSectionHeadingStyle} />
    }
  </div>;
};

const notNewConfigTypeFallback = (theme) => <p
    style={{fontSize: 13, padding: 10, paddingBottom: 0}}
    data-test-id="cannot-be-edited-message">
  This metric cannot be edited, duplicated or inherited from this page.
  If you need to make changes, please <a
    target="_blank"
    style={{color: theme.palette.primary.main}}
    href={bullhornSupportTicketDocsUrl} rel="noreferrer">reach out to support</a>.
</p>;

const Config = betterMemo(
    {displayName: "Config"},
    ({
      kpi,
      typeToGroupingEntity,
      entityNames,
      onChange,
      columns,
      onKpiChange,
      kpisAvailableToCombineWith,
      kpiIdToDependentKpis,
      rootNodes,
      cyclingKpis,
      searchFocused,
      filterText,
      parentKpi,
      idToTemplate,
      combinedKpi,
      combineError
    }) => {
      const {theme} = React.useContext(CustomThemeContext);

      const handleFiltersChange = useConfigFieldChange(kpi, onChange, "filter");

      const handleOwnershipChange = useConfigFieldChange(kpi, onChange, "entityToOwnerTypes");
      const handleDateEntityChange = (columnId, joinPath) => onChange(kpi.setIn(
          ["config", "dateEntityColumnId"],
          columnId).setIn(["config", "dateJoinPath"], joinPath));

      const dateColumns = React.useMemo(() => {
        return columns?.filter(c => c.get("dataType") === "DATE");
      }, [columns]);

      const filterableColumns = React.useMemo(() => {
        const multiCurrencyClient = currentClient.hasPermission(clientPermissions.canSeeMultipleCurrencies);
        let filterableTypes = Immutable.Set(["STRING", "DATE", "PERCENTAGE", "INTEGER", "BOOLEAN"]);
        if (!multiCurrencyClient) {
          filterableTypes = filterableTypes.add("CURRENCY");
        }
        return columns?.filter(c => filterableTypes.includes(c.get("dataType")));
      }, [columns]);

      const allowAutoFocus = !searchFocused && (!filterText || filterText === "");
      const isCombined = React.useMemo(() => kpi.get("combineType")
          === "MERGE_WITH_AND"
          && !!kpi.get("combineWithKpiId"), [kpi]);
      const isOverridableDateColumn = !!kpi.get("isOverridableDateColumn");
      const isCustomDateColumn = getQueryParam(isCombined ? combinedKpi : kpi, "overrideDateFilter");
      const dateTooltip = !isOverridableDateColumn
          ? "The date filtering on this metric cannot be changed"
          : (isCustomDateColumn
              ? "This metric has a custom date filter and can only be edited by support"
              : null);

        const hasKpiOverrideTimeframe = (kpiToCheck) => kpiToCheck && kpiToCheck.has("overrideTimeframe") && kpiToCheck.hasIn(["overrideTimeframe", "type"]) && kpiToCheck.getIn(["overrideTimeframe", "type"]) !== "NONE";

        const parentHasOverrideTimeframe = React.useMemo(() => hasKpiOverrideTimeframe(parentKpi), [parentKpi]);
        const isTimeframeFromPage = React.useMemo(() => (!kpi.hasIn(["overrideTimeframe"]) || kpi.getIn(["overrideTimeframe", "type"]) === "NONE") && !parentHasOverrideTimeframe, [kpi, parentHasOverrideTimeframe]);

      const getDefaultInheritKeysDefault = () => Immutable.Set([
        ...(!kpi.hasIn(["config", "entityToOwnerTypes"]) || Object.keys(kpi.getIn([
          "config",
          "entityToOwnerTypes"])).length === 0 ? ["entityToOwnerTypes"] : []),
        ...(!kpi.hasIn(["config", "dateEntityColumnId"]) ? ["dateEntityColumnId"] : []),
        ...(!kpi.has("overrideTimeframe") || kpi.getIn(["overrideTimeframe", "type"]) === "NONE" ? ["timeframe"] : [])
      ]);

      const [inheritKeys, setInheritKeys] = React.useState(isCombined
          ? getDefaultInheritKeysDefault()
          : Immutable.Set());
      const toggleInheritance = (key) => {
        if (inheritKeys.includes(key)) {
            setInheritKeys(inheritKeys.remove(key));
            if (key === "timeframe") {
                updateCustomTimeframe(timeframeRepo.getDefaultForClient());
            }
        } else {
          setInheritKeys(inheritKeys.add(key));
          if (key === "timeframe") {
              onChange(kpi.set("overrideTimeframe", Immutable.fromJS({type: "NONE"})));
          } else {
            onChange(kpi.deleteIn(["config", key]));
          }
        }
      };

        const updateCustomTimeframe = (timeframe) => {
            onChange(kpi.set("overrideTimeframe", timeframe.getImmutableTimeframe()));
        }

        const toggleCustomTimeframe = () => {
            if (!isTimeframeFromPage) {
                onChange(kpi.set("overrideTimeframe", Immutable.fromJS({type: "NONE"})));
            } else {
                updateCustomTimeframe(timeframeRepo.getDefaultForClient());
            }
        };



      React.useCallback(() => {
        if (!isCombined) {
          setInheritKeys(Immutable.Set());
        }
      }, [isCombined]);

      const parentKpis = kpiIdToDependentKpis
          .get(kpi.get("id"), Immutable.List());
      const kpiIsCombinationParent = !parentKpis
        .filter(kpi => kpi.get("dependencyType") === "COMBINATION")
        .isEmpty();
      const isChildKpi =  kpi.get("combineWithKpiId") != null;
      const combineErrorText = (parentKpi && !parentKpi.get("config"))
          ? "This metric is inheriting from a metric that is in a legacy format. Reach out to support to have this metric corrected."
          : "Inheritance failure. Reach out to support to have this metric corrected."
      const kpiIsComponentOfForwardReport =  parentKpis.some(kpi => kpi.get("dependencyType") === "FORWARD_REPORT");
      const useTimeframeFromPage = "Use Timeframe from Page";
      const timeframeLabelTooltip = "By choosing this option, metrics will always reflect data from the timeframe selected on the page you are on. To choose an override timeframe for this metric, uncheck this box and select a timeframe from the drop-down. For example, you may override the timeframe if using a metric to track sales during an incentive period."
      const template = idToTemplate.get(kpi.get("templateId")) || Immutable.Map();

      return (
          <div>
            {!kpi.get("enabled") && <div style={{padding: 10}}>
              <Hint style={{marginBottom: 0}}>
                <Icon icon="info" style={{color: theme.palette.hints.text}}/>
                <span>This metric has been disabled due to performance issues. {Branding.submitTicketInstructions}</span>
              </Hint>
            </div>}
            {isKpiEditable(kpi, idToTemplate)
                ? <>
                  {kpiIsCombinationParent &&
                    <div style={{padding: 10}}>
                        <Hint style={{marginBottom: 0}}>
                            <Icon icon="info" style={{color: theme.palette.hints.text}}/>
                            <span>Other metrics are inheriting settings from this one.
                                Changes to this metric will impact those metrics that are set up to inherit configuration from this one.
                                Review the Relationships tab to understand how other metrics may be impacted.
                            </span>
                        </Hint>
                    </div>}
                  {isChildKpi && (combineError
                          ? <div style={{padding: 10}}>
                            <Hint
                                style={{
                                  marginBottom: 0,
                                  backgroundColor: theme.palette.error.background,
                                  borderColor: theme.palette.error.main
                                }}>
                              <Icon icon="caution" style={{color: theme.palette.hints.text}} />
                              <span>{combineErrorText}</span>
                            </Hint>
                          </div>
                          : <div style={{padding: 10}}>
                            <Hint style={{marginBottom: 0}}>
                              <Icon icon="info" style={{color: theme.palette.hints.text}}/>
                              <span>You are viewing an inherited metric. This metric inherits some of its properties from
                                  <span style={{fontWeight: "bold"}}>&nbsp;{parentKpi.get("name")}</span>.
                                  Use the options below to select which fields you wish to override.
                              </span>
                            </Hint>
                          </div>
                  )}
                  {kpiIsComponentOfForwardReport &&
                      <div style={{padding: 10}}>
                          <Hint style={{marginBottom: 0}}>
                              <Icon icon="info" style={{color: theme.palette.hints.text}}/>
                              <span>You are viewing a metric that is part of an actionable insight.
                                  Review the Relationships tab to understand how other metrics may be impacted.</span>
                          </Hint>
                      </div>}
                    <TabSection
                        autoExpand
                        title="setup"
                        description="Define initial parameters for your new metric.">
                        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
                            <div
                                style={fieldGridStyle}
                                title={(!isOverridableDateColumn || isCustomDateColumn) && dateTooltip}>
                                {isCombined && <InheritSwitch
                                    style={{position: "absolute"}}
                                    disabled={!isOverridableDateColumn || isCustomDateColumn}
                                    section={"metric"}
                                    inheritKey="dateEntityColumnId"
                                    inheritKeys={inheritKeys}
                                    onToggle={toggleInheritance}/>}
                                <EntityColumnPicker
                                    entityColumnId={isCombined && inheritKeys.has("dateEntityColumnId")
                                        ? parentKpi?.getIn(["config", "dateEntityColumnId"])
                                        : kpi?.getIn(["config", "dateEntityColumnId"])}
                                    joinPath={isCombined && inheritKeys.has("dateEntityColumnId") ? parentKpi?.getIn([
                                        "config",
                                        "dateJoinPath"]) : kpi?.getIn(["config", "dateJoinPath"])}
                                    columns={dateColumns}
                                    label="Date"
                                    labelTooltip="Select the date field that this metric should use to determine whether it should be included in your selected timeframe."
                                    disabled={!isOverridableDateColumn || isCustomDateColumn || (isCombined && inheritKeys.has(
                                        "dateEntityColumnId"))}
                                    placeholder={"Default"}
                                    title={dateTooltip}
                                    onColumnSelect={handleDateEntityChange}/>
                            </div>
                            <div style={fieldGridStyle}>
                                <ConfigInheritFrom
                                    label="Inherits from"
                                    labelTooltip="Select an existing metric to inherit settings from. This metric will inherit settings and filters from the parent metric and will be impacted by any changes made to the parent metric."
                                    kpi={kpi}
                                    kpis={kpisAvailableToCombineWith}
                                    onKpiChange={onKpiChange}
                                    kpiIdToDependentKpis={kpiIdToDependentKpis}
                                    cyclingKpis={cyclingKpis}
                                    rootNodes={rootNodes}/>
                            </div>
                            <div style={fieldGridStyle}>
                                {isCombined && <InheritSwitch
                                    style={{position: "absolute", marginTop: -1}}
                                    section={"metric"}
                                    inheritKey="entityToOwnerTypes"
                                    inheritKeys={inheritKeys}
                                    onToggle={toggleInheritance}/>}
                                <ConfigOwnership
                                    kpi={isCombined && inheritKeys.has("entityToOwnerTypes") ? parentKpi : kpi}
                                    typeToGroupingEntity={typeToGroupingEntity}
                                    entityNames={entityNames}
                                    disabled={isCombined && inheritKeys.has("entityToOwnerTypes")}
                                    onChange={handleOwnershipChange}/>
                            </div>
                        </div>
                        { template.get("dateType") !== "INSTANT" &&
                            <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
                                <div style={fieldGridStyle && {width: "100%"}}>
                                    <div style={{display: "flex", width: 276, margin: "0.25rem 0rem"}}>
                                        <Checkbox
                                            label={useTimeframeFromPage}
                                            tooltip={timeframeLabelTooltip}
                                            style={{marginRight: 0}}
                                            checked={isTimeframeFromPage && (!parentHasOverrideTimeframe || !inheritKeys.has("timeframe"))}
                                            onCheck={toggleCustomTimeframe}
                                            disabled={parentHasOverrideTimeframe}/>
                                    </div>
                                </div>
                                { !isTimeframeFromPage &&
                                    <div style={fieldGridStyle}>
                                    {isCombined && <InheritSwitch
                                            style={{position: "absolute", marginTop: -1}}
                                            section={"metric"}
                                            inheritKey="timeframe"
                                            inheritKeys={inheritKeys}
                                            onToggle={toggleInheritance}/>}
                                        <TimeframePicker
                                            label={"Override Using"}
                                            timeframe={(isCombined && inheritKeys.has("timeframe"))
                                                ? (hasKpiOverrideTimeframe(parentKpi) ? timeframeRepo.parseFromImmutable(parentKpi.get("overrideTimeframe")) : timeframeRepo.getDefaultForClient())
                                                : (hasKpiOverrideTimeframe(kpi) ? timeframeRepo.parseFromImmutable(kpi.get("overrideTimeframe")) : timeframeRepo.getDefaultForClient())}
                                            onChange={updateCustomTimeframe}
                                            isDisabled={isTimeframeFromPage || (isCombined && inheritKeys.has("timeframe"))} />
                                    </div>
                                }
                            </div>
                        }
                    </TabSection>
                    <TabSection
                        autoExpand
                        title="filter"
                        description="Create a filter to narrow down the results of this metric. Conditions can be combined with AND or OR statements and may include up to 3 nested groups.">
                        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
                            {isCombined && parentKpi && <div style={{...fieldGridStyle, width: "100%"}}>
                        {parentKpi?.getIn(["config", "filter"])
                            && renderFilterSectionTitle(
                                inheritedFiltersSectionTitle,
                                "Inherited filters cannot be edited and are set on the parent metric.")}
                        <Filters
                            onChange={handleFiltersChange}
                            columns={filterableColumns}
                            filter={parentKpi?.getIn(["config", "filter"]) || undefined}
                            readOnly
                        />
                      </div>}
                      <div style={{...fieldGridStyle, width: "100%"}}>
                        {parentKpi?.getIn(["config", "filter"]) && renderFilterSectionTitle(
                            filtersSectionTitle,
                            "Metric-specific filters can be combined with inherited metric filters to further narrow down results of this metric.")}
                        <Filters
                            allowAutofocus={allowAutoFocus}
                            onChange={handleFiltersChange}
                            columns={filterableColumns}
                            filter={kpi.getIn(["config", "filter"]) || undefined} />
                      </div>
                    </div>
                  </TabSection>
                </>
                : notNewConfigTypeFallback(theme)
            }
          </div>
      );
    });

export default Config;
