import React from "react";
import pure from "js/common/views/pure";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as Formatter from "js/common/utils/formatter";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const RatioDetails = ({
  ratio,
  ratioData,
  onKpiClick
}) => {
  const {theme} = React.useContext(CustomThemeContext);
  const firstRatioKpiId = ratio.get("firstKpiId");
  const secondRatioKpiId = ratio.get("secondKpiId");
  return (
      <div style={{paddingTop: "1em", paddingBottom: "1em", background: theme.palette.background.paper}}>
        <RatioKpi
            kpi={kpiRepo.get(firstRatioKpiId)}
            value={ratioData.get("firstKpiData").get("total").toJS()}
            onClick={() => onKpiClick(firstRatioKpiId)} />

        <hr style={{border: `1px solid ${theme.themeId === "light" ? theme.palette.background.card : theme.palette.background.light}`, margin: "1rem"}} />

        <RatioKpi
            kpi={kpiRepo.get(secondRatioKpiId)}
            value={ratioData.get("secondKpiData").get("total").toJS()}
            onClick={() => onKpiClick(secondRatioKpiId)} />
      </div>
  );
};

const ratioTextStyle = {
  fontSize: "1.25rem",
  lineHeight: 1.5,
  padding: "0.5rem 1.5rem"
};

const RatioKpi = pure(({
  kpi,
  value,
  onClick
}) => (
    <div className="clearfix TESTCAFE-ratio-detail" onClick={() => onClick(kpi.get("id"))} style={{cursor: "pointer"}}>
        <span className="left" style={ratioTextStyle}>
            {kpi.get("name")}
        </span>
      <span className="right" style={ratioTextStyle}>
            {Formatter.format(value, {valueFormat: kpi.get("valueFormat")})}
        </span>
    </div>
));

export default RatioDetails;