import React from "react";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";

import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as Users from "js/common/users";
import * as Formatter from "js/common/utils/formatter";
import UserAvatar from "js/common/views/user-avatar";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const CurrentTopPerformer = createReactClass({

  mixins: [PureRenderMixin],

  render() {
    const {config, topPerformer, data, theme} = this.props;
    const nonBreakingSpace = "\u00A0";
    const title = config.get("title") || nonBreakingSpace;
    const user = Users.getUser(topPerformer.get("userId"));
    const userFullName = user.get("fullName");
    const hasKpis = !config.get("kpis").isEmpty();
    const imgHeight = config.get("kpis").count() > 1 ? "18vh" : "25vh";
    const userPhotoContainerStyle = {
      height: imgHeight,
      marginTop: hasKpis ? 0 : "2vh",
      display: "flex",
      justifyContent: "space-around"
    };
    const userFullNameStyle = {
      fontFamily: theme.typography.fontFamily,
      fontSize: "2.75vmin",
      color: "#f9ec33",
      lineHeight: 1.25,
      padding: "1.5vh"
    };
    const rankLabelStyle = {
      ...textStyle,
      fontSize: "2vw",
      color: "#08014e",
      textAlign: "center",
      textShadow: "none",
      background: "#f9ec33",
      lineHeight: "2.65vw",
      height: "2.5vw",
      width: "2.5vw",
      borderRadius: "50%",
      position: "absolute",
      zIndex: 100,
      right: "1.5vmin",
      marginTop: "3.55vmin"
    };
    return (
        <div style={{height: "100%"}}>
          <div style={{height: "6vh", display: "flex"}}>
                    <span style={{...headerTextStyle(this.props.theme), alignSelf: "flex-end"}}>
                        {title}
                    </span>
            <span style={rankLabelStyle}>
                        {topPerformer.get("rank")}
                    </span>
          </div>
          <div style={{...leftSideContainerStyle, height: `calc(100% - 6vh)`}}>
            <div style={userPhotoContainerStyle}>
              <UserAvatar user={user} style={{...imgStyle, maxHeight: imgHeight}} />
            </div>
            <div style={userFullNameStyle}>
              {userFullName}
            </div>
            {hasKpis && data && this.renderKpisDataSummary(theme)}
          </div>
        </div>
    );
  },

  renderKpisDataSummary(theme) {
    const maxCharDisplayLength = 10;
    return this.props.data
        .sortBy(data => data.get("order"))
        .map((kpiData, index) => {
          const kpiId = kpiData.get("kpiId");
          const kpi = kpiRepo.get(kpiId);
          const kpiNameLabel = kpiData.get("displayName");
          const kpiValueFormat = kpi.get("valueFormat");
          const summaryContainerStyle = {
            borderTop: "2px solid #534e6e",
            paddingTop: "1.5vh",
            paddingBottom: "1vh"
          };
          const kpiDataLabelStyle = {
            fontWeight: "bold",
            lineHeight: 1.25
          };
          const kpiNameLabelStyle = theme => ({
            ...kpiDataLabelStyle,
            color: "#fff",
            fontFamily: theme.typography.fontFamily,
            fontSize: this.props.data.count() < 3 || kpiNameLabel.length < 40 ? "2.75vmin" : "2.4vmin",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis"
          });
          const kpiValueLabelStyle = theme => ({
            ...kpiDataLabelStyle,
            fontFamily: theme.typography.fontFamily,
            color: "#f9ec33",
            fontSize: "3.8vw"
          });
          const kpiTotal = kpiData.get("total");
          return (
              <div key={`${kpiId}-${index}`} style={summaryContainerStyle}>
                <div style={kpiNameLabelStyle(theme)}>
                  {kpiData.get("displayName")}
                </div>
                <div style={kpiValueLabelStyle(theme)}>
                  {kpiTotal.get("type") === "error" ?
                      kpiTotal.get("value") :
                      Formatter.format(
                          kpiTotal.toJS(),
                          {valueFormat: kpiValueFormat, maxDisplayLength: maxCharDisplayLength})}
                </div>
              </div>
          );
        });
  }

});

const textStyle = theme => ({
  fontFamily: theme.typography.fontFamilyBold,
  fontSize: "3.5vmin",
  color: "#fff",
  textShadow: "0 2px 2px #000"
});

const headerTextStyle = theme => ({
  ...textStyle(theme),
  fontSize: "3vh",
  color: "#f9ec33",
  textTransform: "uppercase",
  lineHeight: 1
});

const imgStyle = {
  objectFit: "contain",
  width: "auto",
  flexGrow: 0,
  filter: "drop-shadow(0 0 10px rgba(191,188,214,0.6))"
};

const leftSideContainerStyle = {
  overflow: "hidden",
  padding: "1rem",
  borderTop: "3px solid #f9ec33",
  borderBottom: "3px solid #f9ec33",
  background: "linear-gradient(to bottom, #0f0763 0%, #0f0763 23%, #030111 100%)",
  textAlign: "center",
  ...textStyle
};

const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <CurrentTopPerformer theme={theme} {...props} />;
};

export default Wrapper;
