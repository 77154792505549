import React, {useCallback, useContext} from "react";
import Immutable from "immutable";
import {ThemeContext} from "@emotion/react";

import pure from "js/common/views/pure";
import {isMobile} from "js/common/ua-parser";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {betterMemo} from "js/common/utils/more-memo";

const isMobileDevice = isMobile();

const Tabs = betterMemo(
    {displayName: "Tabs"},
    ({
      tabs,
      onChangeTab,
      selectedIndex,
      saveTabStateOnChange,
      containerStyle,
      contentStyle,
      tabNavStyle,
      allowAddTab = false,
      addTabLabel = "Add tab",
      onAddTabClick,
      ...props
    }) => {
      const {theme} = useContext(CustomThemeContext);
      const [shownIndexes, setShownIndexes] = React.useState(Immutable.Set());
      const handleTabClick = useCallback((tabIndex) => {
        if (saveTabStateOnChange) {
          setShownIndexes(prevState => prevState.add(tabIndex));
        }
        onChangeTab(tabIndex);
      }, [saveTabStateOnChange, onChangeTab]);
      return (
          <div style={{...containerStyle, height: "100%"}} {...props}>
            <div style={{display: "table"}} data-test-id={"tabs-menu"}>
              {tabs.map((tab, index) => <TabNav
                  key={index}
                  tab={tab}
                  index={index}
                  isSelected={index === selectedIndex}
                  onTabClick={handleTabClick}
                  tabNavStyle={tabNavStyle} />)}
              {allowAddTab && <AddTab label={addTabLabel} onClick={onAddTabClick} />}
            </div>
            <div className={"tab-content-area"} data-test-id={"tabs-content"} style={{height: "100%"}}>
              {tabs
                  .filter((tab, index) => index === selectedIndex || shownIndexes.contains(index))
                  .map(tab => renderTab(tab, contentStyle, theme))}
            </div>
          </div>);
    });


const TabNav = betterMemo(
    {displayName: "TabNav"},
    ({tab, index, isSelected, onTabClick, tabNavStyle}) => {
      const theme = useContext(ThemeContext);
      const baseStyle = {...navItemStyle(theme, isSelected), ...tabNavStyle};
      const bgShade = theme.themeId === "dark" && !isSelected ? {backgroundColor: "rgb(39, 39, 49)"} : {};
      const tabStyle = {
        ...baseStyle,
        ...bgShade
      };
      return (
          <div style={tabStyle} onClick={() => onTabClick(index)} data-test-id="tabs-menu-item">
            {tab.title}
            <div style={isSelected ? activeIndicatorStyle(theme) : inactiveIndicatorStyle} />
          </div>);
    });


const renderTab = (tab, contentStyle, theme) => {
  const contentAreaStyle = {
    borderRadius: "0 3px 3px 3px",
    backgroundColor: theme.themeId === "light" ? theme.palette.background.card : theme.palette.background.paper,
    border: `1px solid ${theme.palette.action.selected}`,
    color: theme.palette.text.main,
    height: "calc(100% - 62px)",  // tab area height (32px) + some padding (10px),
    ...contentStyle
  };

  return (
      <div key={`tab-${tab.title}`} style={contentAreaStyle}>
        {tab.content}
      </div>);
};

const AddTab = pure(({
  label,
  onClick
}) =>
    <span style={addTabStyle} onClick={onClick}>
        <i className="fa fa-plus" style={{paddingRight: 8}} />
      {!isMobileDevice && label}
    </span>);

const activeIndicatorStyle = theme => ({
  position: "absolute",
  height: 2,
  bottom: 0,
  left: 0,
  right: 0,
  width: "100%",
  backgroundColor: theme.palette.primary.main
});

const inactiveIndicatorStyle = {
  position: "absolute",
  width: "100%",
  height: 1,
  right: 0,
  left: 0,
  bottom: 0
};

const navItemStyle = (theme, isSelected) => ({
  display: "inline-block",
  position: "relative",
  fontSize: "0.8rem",
  color: theme.themeId === "light" ? isSelected ? "#000" : "#333" : isSelected ? "#fff" : "rgb(136, 136, 136)",
  paddingTop: 10,
  paddingBottom: 10,
  paddingRight: 10,
  paddingLeft: 10,
  borderRadius: "3px 3px 0 0",
  marginBottom: -1,
  marginRight: theme.themeId === "light" ? "1px" : "3px !important",
  border: theme.themeId === "light" ? "none" : "1px solid rgba(255,255,255,0.2)",
  cursor: "pointer",
  "@media (minWidth: 640px)": {
    marginRight: "3px"
  }
});

const addTabStyle = {
  display: "table-cell",
  verticalAlign: "middle",
  position: "relative",
  color: "#969696",
  fontSize: "0.8rem",
  fontWeight: "bold",
  textTransform: "lowercase",
  paddingTop: 5,
  paddingRight: 15,
  paddingLeft: 15,
  cursor: "pointer",

  ":hover": {
    color: "#fff"
  }
};

export default Tabs;
