/** @jsxImportSource @emotion/react */

import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import PureRenderMixin from "react-addons-pure-render-mixin";
import ImmutablePropTypes from "react-immutable-proptypes";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import {css} from "@emotion/react";

import QuickViewButton from "js/oneview/quick-view-button";
import {Column, Row} from "js/common/views/foundation-column-layout";
import {getColorForPercentage, getPercentageOfTargetCompleteString} from "js/oneview/kpi-values-display";
import * as Formatter from "js/common/utils/formatter";

import pure from "js/common/views/pure";
import currentClient from "js/common/repo/backbone/current-client";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import * as numbers from "js/common/utils/numbers";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";

const KpiSummary = createReactClass({

  mixins: [PureRenderMixin],
  displayName: "KpiSummary",

  propTypes: {
    hasQuickView: ReactPropTypes.bool.isRequired,
    currentTimeframe: ReactPropTypes.object.isRequired,
    isLastKpi: ReactPropTypes.bool.isRequired,
    kpiId: ReactPropTypes.number.isRequired,
    clientIds: ImmutablePropTypes.set,
    data: ImmutablePropTypes.map,
    onClick: ReactPropTypes.func,
    onQuickViewClick: ReactPropTypes.func
  },

  getInitialState() {
    return {
      isActive: false
    };
  },

  render() {
    let {
      onQuickViewClick,
      onClick
    } = this.props;
    const {
      kpiId,
      currentTimeframe,
      clientIds,
      hasQuickView,
      isLastKpi,
      theme
    } = this.props;
    const {palette, themeId} = theme;
    const tooltipId = `kpi-${kpiId}`;
    const kpi = kpiRepo.get(kpiId);

    const hasClientFilter = clientIds && clientIds.count() > 0;
    const isNotClientFilterable = hasClientFilter && !kpi.get("filterableByClient");

    const isInstantDateTypeKpi = kpi.get("type").dateType === "INSTANT";
    const isDataAffectedByTimeframe = timeframeAffectsKpiData(isInstantDateTypeKpi, currentTimeframe);
    const {isActive} = this.state;

    const kpiColumnStyle = {
      cursor: "pointer",
      position: "relative",
      borderTop: `2px solid ${palette.background.paper}`,
      overflow: "hidden",
      padding: "11px 15px 10px 15px",
      whiteSpace: "nowrap",
      margin: "0 0 2px 0"
    };

    const activeKpiHighlightStyle = {
      background: `${palette.primary.main}`,
      position: "absolute",
      left: 0,
      top: 0,
      bottom: 0,
      width: "3px",
      transition: "transform 250ms ease-in-out",
      transform: "scaleY(1)"
    };

    const nonActiveKpiHighlightStyle = {
      background: `${palette.primary.main}`,
      position: "absolute",
      left: 0,
      top: 0,
      bottom: 0,
      width: "3px",
      transition: "transform 250ms ease-in-out",
      transform: "scaleY(0)"
    };

    const containerStyle = isActive ?
        {...kpiColumnStyle, color: palette.primary.main, background: palette.background.light} :
        {...kpiColumnStyle, background: palette.background.card};

    const displayTooltip = isNotClientFilterable || !isDataAffectedByTimeframe;
    let message;
    if (isNotClientFilterable) {
      onQuickViewClick = () => {};
      onClick = () => {};
      message = "This Metric can't be filtered by client";
    } else if (!isDataAffectedByTimeframe) {
      message = "Always current data; timeframe selection not applied.";
    }

    return (
        <Column small={12} medium={6} end={isLastKpi} style={{padding: "0 5px"}}>
          {displayTooltip && <Tooltip id={tooltipId} message={message} />}
          <div
              data-tip={""}
              data-for={tooltipId}
              css={displayTooltip ? css({...containerStyle, opacity: 0.6}) : css(containerStyle)}
              onClick={() => onClick(kpiId)}
              onMouseOver={this.handleMouseOver}
              onMouseOut={this.handleMouseOut}>
            <div style={isActive ? activeKpiHighlightStyle : nonActiveKpiHighlightStyle} />

            <Row>
              <Column small={12} style={{fontSize: themeId === "light" ? 13 : 16}}>
                {hasQuickView &&
                <QuickViewButton
                    onClick={() => onQuickViewClick(kpiId)}
                    customStyle={{float: "left", lineHeight: 1.5, fontSize: "14px", marginRight: "1rem"}} />}

                <span css={css({float: "left"})}>{kpi.get("name")}</span>
                <span css={css({float: "right", overflow: "hidden", maxHeight: 20})}>
                    {isNotClientFilterable ?
                        "N/A" :
                        this.renderKpiSummaryDisplay(kpi)}
                  </span>
              </Column>
            </Row>
          </div>
        </Column>);
  },

  handleMouseOver() {
    this.setState({
      isActive: true
    });
  },

  handleMouseOut() {
    this.setState({
      isActive: false
    });
  },

  renderKpiSummaryDisplay(kpi) {
    const {kpiId, data, clientIds, theme} = this.props;
    if (!data) {
      return <LoadingSpinner palette={theme.palette} />;
    }
    if (data.has("error")) {
      return <span>Error</span>;
    } else {
      const kpiValueFormat = kpi.get("valueFormat");
      const hasTarget = data.has("target") && data.get("target").value > 0;
      const hasClientFilter = clientIds && clientIds.count() > 0;
      if (hasTarget && !hasClientFilter) {
        return <TargetedMetricValueDisplay
            kpiId={kpiId}
            kpi={kpi}
            data={data}
            kpiValueFormat={kpiValueFormat}
            theme={theme}
        />;
      } else {
        const formattedTotalValue = Formatter.format(data.get("total"), {valueFormat: kpiValueFormat});
        return (
            <div css={css({color: theme.palette.text.main, fontFamily: theme.typography.fontFamilyBold, fontWeight: "600"})}>
              {formattedTotalValue}
            </div>);
      }
    }
  }

});

const TargetedMetricValueDisplay = pure(({kpiId, data, kpiValueFormat, theme}) => {
  const formattedTotalValue = Formatter.format(data.get("total"), {valueFormat: kpiValueFormat});
  const tooltipId = `target-${kpiId}`;
  const percentOfTarget = getPercentageOfTargetCompleteString(getSafely(data, "total").value, getSafely(data, "target").value);
  const difference = getTotalToExpectedTargetDifference(data);
  const tooltipLabel = `${percentOfTarget} ${difference}`;

  const hasColorCodedOneViewTargets = currentClient.hasPermission("COLOR_CODED_ONEVIEW_TARGETS");
  const textColor = !hasColorCodedOneViewTargets && data.get("priority") ? theme.palette.primary.main : null;
  const targetValue = Formatter.format(data.get("target"), {valueFormat: kpiValueFormat});

  const valueColor = hasColorCodedOneViewTargets ? getTargetedMetricDisplayColor(data, theme.palette) : theme.palette.text.main;
  return (
      <div>
        <div
            data-tip={""}
            data-for={tooltipId}
            data-offset="{'top': 3, 'right': -7}"
            css={css({color: valueColor})}>
          <span css={css({fontFamily: theme.typography.fontFamilyBold, fontWeight: "600" })}>{`${formattedTotalValue} / `}</span>
          <span css={css({color: textColor, fontFamily: theme.typography.fontFamilyBold, fontWeight: "600" })}>{targetValue}</span>
        </div>
        <Tooltip id={tooltipId} message={tooltipLabel} />
      </div>);
});

const Tooltip = pure(({
  id,
  message
}) => <ReactTooltip class="cube19-dark" id={id} place="top" effect="solid">{message}</ReactTooltip>);

const LoadingSpinner = ({palette}) => {
  return <i className="fa fa-spinner fa-pulse"
            style={{color: palette.primary.main}} />;
};

const timeframeContainsToday = timeframe => {
  const startDate = timeframe.getStart();
  const endDate = timeframe.getEnd();
  const today = moment();
  return today.isBetween(startDate, endDate, "days", "[]");
};

const getTargetedMetricDisplayColor = (kpiData, palette) => {
  const total = getSafely(kpiData, "total").value;
  const expectedTarget = numbers.roundTo(getSafely(kpiData, "expectedValue").value, 3);
  const percentOfExpectedTarget = expectedTarget === 0 ? 0 : total / expectedTarget;
  const color = getColorForPercentage(percentOfExpectedTarget);
  return color === "green" ? palette.success.main : color;
};

const getTotalToExpectedTargetDifference = kpiData => {
  const total = getSafely(kpiData, "total");
  const expectedValue = numbers.roundTo(getSafely(kpiData, "expectedValue").value, 3);
  const differenceFromExpectedValue = total.value - expectedValue;
  const difference = {
    value: Math.abs(differenceFromExpectedValue),
    currency: total.currency,
    decimalPlaces: total.decimalPlaces
  };
  return differenceFromExpectedValue < 0 ? `(${Formatter.format(difference)} behind expected)` : "";
};

const getSafely = (kpiData, key) => {
  if (!kpiData.has(key)) {
    return {
      value: 0
    };
  }

  let obj = kpiData.get(key);
  if (!obj.value) {
    obj.value = 0;
  }
  return obj;
};

export const timeframeAffectsKpiData = (isInstantKpi, timeframe) => !(isInstantKpi &&
    !timeframeContainsToday(timeframe));

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <KpiSummary theme={theme} {...props} />;
}
