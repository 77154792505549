import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import moment from "moment";
import ImmutablePropTypes from "react-immutable-proptypes";
import PureRenderMixin from "react-addons-pure-render-mixin";

import {Column, Row} from "js/common/views/foundation-column-layout";
import ErrorMsg from "js/common/views/error";
import ArrowIcon from "js/oneview/targets/arrow-icon";
import SimpleTextInput from "js/common/views/inputs/simple-text-input";
import CurrencyPicker from "js/oneview/targets/currency-picker";
import PeriodTypePicker from "js/oneview/targets/period-type-picker";
import DatePicker from "js/common/views/inputs/timeframe-picker/react-datepicker";
import NotesSummary from "js/oneview/targets/notes-summary";
import * as popups from "js/common/popups";
import currentClient from "js/common/repo/backbone/current-client";

const errorMsgStyle = {
  marginTop: "0.5rem",
  marginBottom: 0
};

const EditableTarget = createReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
    target: ImmutablePropTypes.map.isRequired,
    theme: ReactPropTypes.object,
    isTargetInTimeframe: ReactPropTypes.bool.isRequired,
    highlightCurrentTarget: ReactPropTypes.bool.isRequired,
    onClick: ReactPropTypes.func.isRequired,
    onChange: ReactPropTypes.func.isRequired,
    isNotesPanelOpen: ReactPropTypes.bool.isRequired,
    isSelected: ReactPropTypes.bool,
    error: ReactPropTypes.string,
    setOpenDatePicker: ReactPropTypes.func
  },

  getDefaultProps() {
    return {
      isSelected: false
    };
  },

  render() {
    const {
      target,
      theme,
      isTargetInTimeframe,
      highlightCurrentTarget,
      isSelected,
      onClick,
      onChange,
      error,
      isNotesPanelOpen
    } = this.props;
    const startDate = target.get("targetStart");
    const endDate = target.get("targetEnd");
    const isDateRangeInvalid = !startDate.isValid() || (endDate && !endDate.isValid());
    const isPriority = target.get("priority");
    const iconToggleStyle = {
      cursor: "pointer",
      fontSize: "1rem",
      lineHeight: "34px"
    };
    const priorityIconStyle = {
      ...iconToggleStyle,
      flex: 0.2,
      paddingLeft: "1rem",
      lineHeight: "34px",
      color: isPriority ? theme.palette.primary.main : (theme.themeId === "light"
          ? theme.palette.action.disabled
          : theme.palette.textColor)
    };
    const deleteIconStyle = {
      ...iconToggleStyle,
      width: "1rem",
      color: "#f00",
      fontSize: "1.2rem"
    };
    const columnStyle = {flex: 1, paddingRight: "0.5rem"};
    const notesColumnStyle = {
      flex: isNotesPanelOpen ? "0.2" : 1,
      paddingRight: "0.5rem",
      alignSelf: "flex-start",
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 5
    };
    return (
        <div
            className={`table-${theme.themeId} target-row ${isSelected ? "is-selected" : ""} ${highlightCurrentTarget
            && isTargetInTimeframe ?
                "is-in-timeframe" : ""}`}
            onClick={() => onClick(target.get("id"))}>
          <div style={{display: "flex", alignItems: "center"}}>
            <i
                className={`fa fa-${isPriority ? "star" : "star-o"}`}
                style={priorityIconStyle}
                onClick={e => {
                  e.stopPropagation();
                  onChange(target.set("priority", !target.get("priority")));
                }} />
            <div style={columnStyle}>
              {this.renderValueColumn()}
              {this.renderValueValidationError()}
            </div>
            <div className={`picker-${theme.themeId}`} style={columnStyle}>
              {this.renderDateRangeColumn()}
              {isDateRangeInvalid && this.renderDatesValidationError()}
            </div>
            <div style={{...notesColumnStyle}}>
              <NotesSummary
                  theme={theme}
                  viewType={isNotesPanelOpen ? "COMPACT" : "FULL"}
                  notes={target.get("noteAssignmentDtos")} />
            </div>
            <div>
              <i
                  className="fa fa-times"
                  title="Delete target"
                  style={deleteIconStyle}
                  onClick={this.handleDeleteClick} />
            </div>
          </div>
          {error && <ErrorMsg text={error} style={errorMsgStyle} />}
        </div>);
  },

  renderValueColumn() {
    const {target, onChange} = this.props;
    const targetValueWrapper = target.get("value");
    const isMonetaryTarget = !!targetValueWrapper.get("currency");
    const targetCurrency = targetValueWrapper.get("currency");
    return (
        <Row collapse={true}>
          {isMonetaryTarget &&
              <Column small={12} large={3} style={{marginRight: "0.25rem"}}>
                <CurrencyPicker
                    value={targetCurrency}
                    onChange={currency => {
                      onChange(target.setIn(["value", "currency"], currency));
                    }}
                    style={{minWidth: 0}} />
              </Column>}
          <Column small={12} large={isMonetaryTarget ? 4 : 5} style={{marginRight: "0.25rem"}}>
            <TargetValueInput initialValue={targetValueWrapper.get("value")} onChange={this.handleValueChange} />
          </Column>
          <Column small={12} large={isMonetaryTarget ? 4 : 5} end={true}>
            {currentClient.hasPermission("USES_445_CALENDAR") ?
                <div style={{height: 36, lineHeight: "36px", paddingLeft: "0.5rem"}}>per week</div> :
                <PeriodTypePicker
                    value={target.get("period")}
                    onChange={period => onChange(target.set("period", period))} />}
          </Column>
        </Row>
    );
  },

  renderValueValidationError() {
    const targetValue = this.props.target.getIn(["value", "value"]);
    const minValue = 0;
    const maxValue = 999999999999999;
    if (isNaN(targetValue) || (!targetValue && targetValue !== 0)) {
      return <ErrorMsg text="Numerical value required" style={errorMsgStyle} />;
    } else if (targetValue < minValue) {
      return <ErrorMsg text="Target should not be a negative value" style={errorMsgStyle} />;
    } else if (targetValue > maxValue) {
      return <ErrorMsg text={`Target should not be over ${maxValue}`} style={errorMsgStyle} />;
    } else {
      return null;
    }
  },

  renderDateRangeColumn() {
    const {target, onChange, setOpenDatePicker} = this.props;
    const startDate = target.get("targetStart");
    const endDate = target.get("targetEnd");
    return (
        <Row collapse={true}>
          <Column small={12} large={5}>
            <DatePicker
                setOpenDatePicker={setOpenDatePicker}
                hideError={true}
                value={startDate}
                onDateChange={startDate => onChange(target.set("targetStart", startDate))} />
          </Column>
          <Column small={12} large={1} className="text-center">
            <ArrowIcon style={{lineHeight: "34px"}} />
          </Column>
          <Column small={12} large={5} end={true}>
            <DatePicker
                setOpenDatePicker={setOpenDatePicker}
                hideError={true}
                value={isFarFutureDate(endDate) ? null : endDate}
                onDateChange={this.handleEndDateChange} />
          </Column>
        </Row>
    );
  },

  renderDatesValidationError() {
    const {target} = this.props;
    const startDate = target.get("targetStart");
    const endDate = target.get("targetEnd");
    let invalidDatesText = "Dates";
    if (!startDate.isValid() && !endDate.isValid()) {
      invalidDatesText = "Start and End Dates";
    }
    if (!startDate.isValid() && endDate.isValid()) {
      invalidDatesText = "Start Date";
    }
    if (startDate.isValid() && !endDate.isValid()) {
      invalidDatesText = "End Date";
    }
    return <ErrorMsg
        text={`${invalidDatesText} should be in the format ${moment.localeData().longDateFormat.L}`}
        style={errorMsgStyle} />;
  },

  handleDeleteClick(e) {
    e.stopPropagation();
    const message = "<p>Deleting a target is permanent and any notes on this target will no longer be visible. Changing the end date on an existing target is usually the right thing to do.</p><br/><p>Are you sure you want to delete this target?</p>";
    popups.confirm(message, {
      title: "Delete Target",
      labels: {
        cancel: "No",
        ok: "Yes"
      },
      onok: () => {
        const {target, onChange} = this.props;
        onChange(target.set("deleted", true));
      }
    });
  },

  handleValueChange(value) {
    const {target, onChange} = this.props;
    onChange(target.setIn(["value", "value"], value));
  },

  handleEndDateChange(endDate, endDateInputValue) {
    const {target, onChange} = this.props;
    if (!endDate.isValid() && !endDateInputValue) {
      onChange(target.set("targetEnd", getFarFutureDate()));
      return;
    }
    onChange(target.set("targetEnd", endDate));
  }

});

const TargetValueInput = createReactClass({

  propTypes: {
    initialValue: ReactPropTypes.number.isRequired,
    onChange: ReactPropTypes.func.isRequired
  },

  getInitialState() {
    return {
      hasChanged: false,
      value: this.props.initialValue
    };
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.initialValue !== this.props.initialValue) {
      this.setState({
        value: nextProps.initialValue
      });
    }
  },

  render() {
    return (
        <SimpleTextInput
            type="number"
            placeholder="Target Value"
            value={this.state.value}
            customStyle={{border: "none"}}
            onChange={this.handleChange}
            onClick={e => e.stopPropagation()}
            onBlur={this.handleBlur} />
    );
  },

  handleChange(value) {
    this.setState({
      hasChanged: true,
      value
    });
  },

  handleBlur() {
    const {hasChanged, value} = this.state;
    if (!hasChanged) {
      return;
    }
    this.setState({
      hasChanged: false
    });
    const num = isNaN(value) || !value ? "0" : value;
    this.props.onChange(num);
  }

});

const getFarFutureDate = () => parseDate("2099-01-01");
const isFarFutureDate = dateStr => parseDate(dateStr).isSameOrAfter(getFarFutureDate(), "day");

const parseDate = (dateStr, format = "YYYY-MM-DD") => moment(dateStr, format);

export default EditableTarget;